import { NgModule } from '@angular/core';
import { DelonFormModule, WidgetRegistry } from '@delon/form';
import { NgxTinymceModule } from 'ngx-tinymce';

import { InputButtonWidget } from './input-button/input-button.widget';
import { TinymceWidget } from './tinymce/tinymce.widget';
import { ViewWidget } from './view/view.widget';
import { SharedModule } from '../shared.module';
import { TimeRangWidget } from './time-range/time-rang.widget';

export const SCHEMA_THIRDS_COMPONENTS = [ViewWidget, InputButtonWidget, TinymceWidget, TimeRangWidget];

@NgModule({
  declarations: SCHEMA_THIRDS_COMPONENTS,
  imports: [
    SharedModule,
    DelonFormModule.forRoot(),
    NgxTinymceModule.forRoot({
      baseURL: './assets/tinymce/',
      config: {}
    })
  ],
  exports: SCHEMA_THIRDS_COMPONENTS
})
export class JsonSchemaModule {
  constructor(widgetRegistry: WidgetRegistry) {
    // widgetRegistry.register(TestWidget.KEY, TestWidget);
    widgetRegistry.register(ViewWidget.KEY, ViewWidget);
    widgetRegistry.register(InputButtonWidget.KEY, InputButtonWidget);
    widgetRegistry.register(TinymceWidget.KEY, TinymceWidget);
    widgetRegistry.register(TimeRangWidget.KEY, TimeRangWidget);
  }
}
