import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-x-form-date-weather',
  templateUrl: './index.component.html',
  styles: [``]
})
export class XFormDateWeatherComponent {
  currentDate: Date = new Date();

  formattedDate: string | null = '';

  constructor(
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
    // this.formattedDate = this.formatDate(this.currentDate);
  }
  timerId: any;
  ngAfterViewInit(): void {
    this.timerId = setInterval(() => {
      this.formattedDate = this.formatDate(new Date());
      this.cdr.detectChanges();
    }, 1000);
  }
  ngOnDestroy(): void {
    clearInterval(this.timerId);
  }
  private formatDate(date: Date): string | null {
    // 格式化日期，显示年月日和星期
    return this.datePipe.transform(date, 'yyyy年M月d日 EEEE HH:mm:ss');
  }
  @Input()
  backgroundColor!: string;
  @Input()
  fontSize!: string;
  @Input()
  fontColor!: string;
}
