import { Injectable, Injector } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';

@Injectable()
export class BaseService {
  protected cacheSrv!: CacheService;
  protected tokenSrv!: ITokenService;
  constructor(protected injector: Injector) {
    this.initialize();
  }
  protected initialize() {
    this.cacheSrv = this.injector.get<CacheService>(CacheService);
    this.tokenSrv = this.injector.get<ITokenService>(DA_SERVICE_TOKEN);
  }
}
