import { Component, OnInit } from '@angular/core';
import { BaseIndexComponent } from 'src/app/shared/component/app/curd/base-index.component';
import { CompanySexIndexComponent } from '../../code/sex/index.component';
import { CompanyMaterialGroupReadComponent } from './read.component';
import { CompanyMaterialGroupSaveComponent } from './save.component';
import { CompanyMaterialGroupUpdateComponent } from './update.component';
import { IKVPaire } from 'src/app/shared/model/core.d';
@Component({
  selector: 'app-company-MaterialGroup-index',
  templateUrl: './../../../../shared/component/app/curd/base-index.component.html'
})
export class CompanyMaterialGroupIndexComponent extends BaseIndexComponent implements OnInit {
  override ngOnInit() {
    this.title = '素材分组';
    this.baseUri = 'company/v1/source_material_group';
    this.dictId = 1011;
    this.menuCode = 'MN0301';
    super.ngOnInit();
    this.dictComponent = {
      1011: CompanyMaterialGroupIndexComponent
    };
    this.readComponent = CompanyMaterialGroupReadComponent;
    this.saveComponent = CompanyMaterialGroupSaveComponent;
    this.updateComponent = CompanyMaterialGroupUpdateComponent;
  }
  /**
   * 初始化对话框
   *
   * @param params 界面参数
   */
  override initDialog(params: IKVPaire) {
    if (!params || false !== params['isDialog']) {
      super.initDialog(params);
    }
  }
}
