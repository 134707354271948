<div nz-row style="height: 47px; background-color: #fff; margin-top: 9px" nzAlign="middle">
  <div nz-col nzSpan="12" style="padding-left: 10px">
    <button nz-button nzType="default" (click)="onBack('back')"><i nz-icon nzType="arrow-left" nzTheme="outline"></i> 返回</button>
  </div>
  <div nz-col nzSpan="12" style="text-align: right"
    ><button
      nz-button
      nzType="default"
      nzDanger
      nz-popconfirm
      nzPopconfirmPlacement="bottom"
      [nzPopconfirmTitle]="'清空？'"
      (nzOnConfirm)="config.children = []; activeNode = null; propertyTabSelectedIndex = 1"
    >
      清空</button
    >
    <button nz-button nzType="default" (click)="openPreviewDialog()"><i nz-icon nzType="search" nzTheme="outline"></i> 预览</button>
    <button nz-button nzType="primary" (click)="onBack('save')" [nzLoading]="SaveButtonLoading"
      ><i nz-icon nzType="save" nzTheme="outline"></i> 保存</button
    >
  </div>
</div>
<div class="container row">
  <div class="components flexNone column" style="border-top: 1px solid rgb(0 0 0 / 15%)">
    <!-- <div class="components__title flexNone">
      <div class="x-form-logo"></div>
      <span>表单设计器</span>
      <a target="_blank" href="https://gitee.com/xujz520/ng-x-form">v1.0.3</a>
    </div> -->
    <div class="components__content">
      <nz-collapse>
        <ng-container *ngFor="let group of componentsGroup">
          <!-- 内联表单不显示布局组件 -->
          <nz-collapse-panel *ngIf="!(group.type == 'layout' && config.layout == 'inline')" [nzHeader]="group.text" [nzActive]="true">
            <div class="component_group">
              <div class="component" *ngFor="let component of group.children || []">
                <div
                  class="component_content"
                  draggable="true"
                  (dragstart)="onDragstart($event, component.nodeData)"
                  (dragend)="onDragend($event)"
                  (click)="onClickAdd(component.nodeData)"
                >
                  <span class="iconfont {{ component.icon }}"></span>
                  <span class="component_text">{{ component.text }}</span>
                </div>
              </div>
            </div>
          </nz-collapse-panel>
        </ng-container>
      </nz-collapse>
    </div>
  </div>

  <div class="workbench column">
    <!-- <div class="workbench__title flexNone">
      <ng-container *ngIf="showSaveButton">
        <button nz-button nzType="default" (click)="onBack('back')"><i nz-icon nzType="arrow-left"
            nzTheme="outline"></i> 返回</button>
        <button nz-button nzType="primary" (click)="onBack('save')" [nzLoading]="SaveButtonLoading"><i nz-icon
            nzType="save" nzTheme="outline"></i> 保存并返回</button>
      </ng-container>
      <nz-button-group>
        <button nz-button nzType="default" style="padding: 0 8px; color: red" nz-popconfirm
          nzPopconfirmPlacement="bottom" [nzPopconfirmTitle]="'清空？'"
          (nzOnConfirm)="config.children = []; activeNode = null; propertyTabSelectedIndex = 1">清空</button> -->
    <!-- <button nz-button nzType="default" style="padding: 0 8px" (click)="openJsonEditDialog()">编辑JSON</button>
        <button nz-button nzType="default" style="padding: 0 8px" (click)="openHtmlDialog()">生成HTML</button> -->
    <!-- </nz-button-group>
      <button nz-button nzType="primary" nzGhost (click)="openPreviewDialog()"><i nz-icon nzType="search"
          nzTheme="outline"></i> 预览</button>
    </div> -->
    <div class="workbench__content">
      <div class="empty_title" *ngIf="(config.children || []).length == 0">从左侧组件库中拖拽或点击</div>
      <div class="x-form-wrap">
        <form
          nz-form
          class="x-form"
          [class.x-form-inline-vertical]="config.layout == 'inline' && config.inlineVertical"
          [style.width.px]="config.containerWidth"
          [style.height.px]="config.show_height"
          [nzLayout]="config.layout"
          #$form
          [attr.data-dom-data]="saveDomData($form, config)"
        >
          <div class="dropList" [attr.data-orientation]="config.layout == 'inline' ? 'horizontal' : 'vertical'" data-type="form">
            <ng-container *ngTemplateOutlet="componentTpl; context: { $implicit: config }"></ng-container>

            <!-- 表单循环模板 -->
            <ng-template #componentTpl let-node>
              <ng-container *ngFor="let item of node.children || []">
                <ng-container [ngSwitch]="item.type">
                  <!-- 分割线 -->
                  <ng-container *ngSwitchCase="'hr'">
                    <div
                      class="form_item_container dragItem form-item-hr"
                      (dragstart)="onDragstart($event, item, node)"
                      (dragend)="onDragend($event)"
                    >
                      <div class="form_item" [class.isActive]="activeNode == item" (click)="activeNode = item">
                        <ng-container *ngTemplateOutlet="form_item_control; context: { item: item, parentNode: node }"> </ng-container>
                      </div>
                      <div style="overflow: hidden">
                        <hr
                          [class.x-form-item-hidden]="item.hidden"
                          [style.margin-top.px]="item.margin"
                          [style.margin-bottom.px]="item.margin"
                        />
                      </div>
                    </div>
                  </ng-container>
                  <!-- 模板占位符 -->
                  <ng-container *ngSwitchCase="'template'">
                    <div
                      class="form_item_container dragItem"
                      [class.hasError]="errorSet.has(item)"
                      (dragstart)="onDragstart($event, item, node)"
                      (dragend)="onDragend($event)"
                    >
                      <div class="form_item" [class.isActive]="activeNode == item" (click)="activeNode = item">
                        <ng-container *ngTemplateOutlet="form_item_control; context: { item: item, parentNode: node }"> </ng-container>
                      </div>
                      <div class="template_placeholder">模板占位符</div>
                    </div>
                  </ng-container>
                  <!-- 图片 -->
                  <ng-container *ngSwitchCase="'img'">
                    <div
                      class="form_item_container dragItem"
                      [class.hasError]="errorSet.has(item)"
                      (dragstart)="onDragstart($event, item, node)"
                      (dragend)="onDragend($event)"
                    >
                      <div class="form_item" [class.isActive]="activeNode == item" (click)="activeNode = item">
                        <ng-container *ngTemplateOutlet="form_item_control; context: { item: item, parentNode: node }"> </ng-container>
                      </div>
                      <div [style.height.px]="item.height">
                        <app-x-form-swiper-img
                          [xFormAutoPlaySpeed]="item.scrollSpeed"
                          [strategy]="item.strategy"
                          [items]="item.carouselItems"
                        ></app-x-form-swiper-img>
                      </div>
                    </div>
                  </ng-container>
                  <!-- 视频 -->
                  <ng-container *ngSwitchCase="'video'">
                    <div
                      class="form_item_container dragItem"
                      [class.hasError]="errorSet.has(item)"
                      (dragstart)="onDragstart($event, item, node)"
                      (dragend)="onDragend($event)"
                    >
                      <div class="form_item" [class.isActive]="activeNode == item" (click)="activeNode = item">
                        <ng-container *ngTemplateOutlet="form_item_control; context: { item: item, parentNode: node }"> </ng-container>
                      </div>

                      <div [style.height.px]="item.height">
                        <app-x-form-swiper-video
                          [height]="item.height"
                          [strategy]="item.strategy"
                          [items]="item.carouselItems"
                        ></app-x-form-swiper-video>
                      </div>
                    </div>
                  </ng-container>

                  <!-- 滚动字幕 -->
                  <ng-container *ngSwitchCase="'scroll-text'">
                    <div
                      class="form_item_container dragItem"
                      [class.hasError]="errorSet.has(item)"
                      (dragstart)="onDragstart($event, item, node)"
                      (dragend)="onDragend($event)"
                    >
                      <div class="form_item" [class.isActive]="activeNode == item" (click)="activeNode = item">
                        <ng-container *ngTemplateOutlet="form_item_control; context: { item: item, parentNode: node }"> </ng-container>
                      </div>

                      <div [style.height.px]="item.height" style="width: 100%">
                        <app-scroll-text
                          [backgroundColor]="item.backgroundColor"
                          [fontColor]="item.fontColor"
                          [fontSize]="item.fontSize + 'px'"
                          [text]="item.text"
                          [scrollSpeed]="item.scrollSpeed + 's'"
                        ></app-scroll-text>
                      </div>
                    </div>
                  </ng-container>

                  <!-- 日期天气 -->
                  <ng-container *ngSwitchCase="'date-weather'">
                    <div
                      class="form_item_container dragItem"
                      [class.hasError]="errorSet.has(item)"
                      (dragstart)="onDragstart($event, item, node)"
                      (dragend)="onDragend($event)"
                    >
                      <div class="form_item" [class.isActive]="activeNode == item" (click)="activeNode = item">
                        <ng-container *ngTemplateOutlet="form_item_control; context: { item: item, parentNode: node }"> </ng-container>
                      </div>
                      <div [style.height.px]="item.height" style="width: 100%">
                        <app-x-form-date-weather
                          [backgroundColor]="item.backgroundColor"
                          [fontColor]="item.fontColor"
                          [fontSize]="item.fontSize + 'px'"
                        ></app-x-form-date-weather>
                      </div>
                    </div>
                  </ng-container>
                  <!-- 站点 -->
                  <ng-container *ngSwitchCase="'station'">
                    <div
                      class="form_item_container dragItem"
                      [class.hasError]="errorSet.has(item)"
                      (dragstart)="onDragstart($event, item, node)"
                      (dragend)="onDragend($event)"
                    >
                      <div class="form_item" [class.isActive]="activeNode == item" (click)="activeNode = item">
                        <ng-container *ngTemplateOutlet="form_item_control; context: { item: item, parentNode: node }"> </ng-container>
                      </div>
                      <div [style.height.px]="item.height" style="width: 100%">
                        <app-x-form-progress [backgroundColor]="item.backgroundColor" [fontColor]="item.fontColor"></app-x-form-progress>
                      </div>
                    </div>
                  </ng-container>
                  <!-- 栅格 -->
                  <ng-container *ngSwitchCase="'row'">
                    <div class="dragItem" (dragstart)="onDragstart($event, item, node)" (dragend)="onDragend($event)">
                      <div
                        class="form_row"
                        [class.isActive]="activeNode == item"
                        (click)="activeNode = item"
                        [class.isHover]="hoverNode == item"
                        (mouseover)="hoverNode = item"
                        (mouseout)="hoverNode = null"
                        #$row
                        [attr.data-dom-data]="saveDomData($row, item)"
                      >
                        <ng-container *ngTemplateOutlet="form_item_control; context: { item: item, parentNode: node }"> </ng-container>
                        <div
                          class="form_content"
                          (click)="$event.stopPropagation()"
                          (mouseover)="$event.stopPropagation()"
                          (mouseout)="$event.stopPropagation()"
                        >
                          <div nz-row [nzGutter]="item.nzGutter" class="dropList" data-orientation="horizontal" data-type="row">
                            <div
                              nz-col
                              [nzSpan]="col.nzSpan"
                              class="dragItem"
                              *ngFor="let col of item.children || []"
                              (dragstart)="onDragstart($event, col, item)"
                              (dragend)="onDragend($event)"
                            >
                              <div
                                class="form_col"
                                [class.isActive]="activeNode == col"
                                (click)="activeNode = col"
                                [class.isHover]="hoverNode == col"
                                (mouseover)="hoverNode = col"
                                (mouseout)="hoverNode = null"
                                #$col
                                [attr.data-dom-data]="saveDomData($col, col)"
                              >
                                <ng-container *ngTemplateOutlet="form_item_control; context: { item: col, parentNode: item }">
                                </ng-container>
                                <div
                                  class="form_content dropList"
                                  data-type="col"
                                  (click)="$event.stopPropagation()"
                                  (mouseover)="$event.stopPropagation()"
                                  (mouseout)="$event.stopPropagation()"
                                >
                                  <ng-container *ngTemplateOutlet="componentTpl; context: { $implicit: col }"> </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- 表单组件 -->
                  <ng-container *ngSwitchDefault>
                    <nz-form-item
                      class="form_item_container dragItem"
                      [class.hasError]="errorSet.has(item)"
                      (dragstart)="onDragstart($event, item, node)"
                      (dragend)="onDragend($event)"
                    >
                      <div class="form_item" [class.isActive]="activeNode == item" (click)="activeNode = item">
                        <ng-container *ngTemplateOutlet="form_item_control; context: { item: item, parentNode: node }"> </ng-container>
                      </div>
                      <nz-form-label
                        [nzSpan]="config.layout == 'horizontal' && !item.labelWidth ? node.labelNzSpan || config.labelNzSpan : null"
                        [nzRequired]="item.required"
                        [class.x-form-vertical-align-top]="item.type == 'textarea' || item.layout == 'vertical'"
                        [style.width.px]="item.labelWidth || null"
                        >{{ item.label }}</nz-form-label
                      >
                      <nz-form-control
                        [nzSpan]="config.layout == 'horizontal' && !item.labelWidth ? node.controlNzSpan || config.controlNzSpan : null"
                        [style.width.px]="xFormService.getComponentWidth(item, config)"
                      >
                        <ng-container [ngSwitch]="item.type">
                          <!-- input -->
                          <ng-container *ngSwitchCase="'input'">
                            <ng-container *ngIf="!(item.nzAddOnBefore || item.nzAddOnAfter)">
                              <input
                                nz-input
                                [attr.type]="item.inputType"
                                [ngModel]="item.defaultValue"
                                [ngModelOptions]="{ standalone: true }"
                                [attr.placeholder]="item.placeholder"
                                [nzSize]="config.nzSize"
                              />
                            </ng-container>
                            <ng-container *ngIf="item.nzAddOnBefore || item.nzAddOnAfter">
                              <nz-input-group
                                [nzAddOnBefore]="item.nzAddOnBefore"
                                [nzAddOnAfter]="item.nzAddOnAfter"
                                [nzSize]="config.nzSize"
                              >
                                <input
                                  nz-input
                                  [attr.type]="item.inputType"
                                  [ngModel]="item.defaultValue"
                                  [ngModelOptions]="{ standalone: true }"
                                  [attr.placeholder]="item.placeholder"
                                />
                              </nz-input-group>
                            </ng-container>
                          </ng-container>
                          <!-- textarea -->
                          <ng-container *ngSwitchCase="'textarea'">
                            <textarea
                              nz-input
                              [ngModel]="item.defaultValue"
                              [nzAutosize]="getPropertyObj(item, 'nzAutosize')"
                              [ngModelOptions]="{ standalone: true }"
                              [attr.placeholder]="item.placeholder"
                              [nzSize]="config.nzSize"
                            ></textarea>
                          </ng-container>
                          <!-- radio -->
                          <ng-container *ngSwitchCase="'radio'">
                            <nz-radio-group
                              nzButtonStyle="solid"
                              [ngModel]="item.defaultValue"
                              [ngModelOptions]="{ standalone: true }"
                              [class.x-form-option-vertical]="item.layout == 'vertical'"
                              [nzSize]="config.nzSize"
                            >
                              <ng-container *ngIf="!item.buttonStyle">
                                <label nz-radio *ngFor="let option of item.options || []" [nzValue]="option.value">{{
                                  option.label
                                }}</label>
                              </ng-container>
                              <ng-container *ngIf="item.buttonStyle">
                                <label nz-radio-button *ngFor="let option of item.options || []" [nzValue]="option.value">{{
                                  option.label
                                }}</label>
                              </ng-container>
                            </nz-radio-group>
                          </ng-container>
                          <!-- checkbox -->
                          <ng-container *ngSwitchCase="'checkbox'">
                            <nz-checkbox-group
                              [ngModel]="item.options"
                              [ngModelOptions]="{ standalone: true }"
                              [class.x-form-option-vertical]="item.layout == 'vertical'"
                            ></nz-checkbox-group>
                          </ng-container>
                          <!-- switch -->
                          <ng-container *ngSwitchCase="'switch'">
                            <nz-switch
                              [ngModel]="item.defaultValue"
                              [nzCheckedChildren]="item.nzCheckedChildren"
                              [nzUnCheckedChildren]="item.nzUnCheckedChildren"
                              [ngModelOptions]="{ standalone: true }"
                              [nzSize]="config.nzSize"
                              style="min-width: 70px"
                            ></nz-switch>
                          </ng-container>
                          <!-- select -->
                          <ng-container *ngSwitchCase="'select'">
                            <nz-select
                              [ngModel]="item.defaultValue"
                              [nzMode]="item.nzMode"
                              nzAllowClear
                              nzShowSearch
                              [nzPlaceHolder]="item.placeholder"
                              [nzDropdownMatchSelectWidth]="false"
                              [ngModelOptions]="{ standalone: true }"
                              [nzSize]="config.nzSize"
                            >
                              <nz-option *ngFor="let option of item.options" [nzValue]="option.value" [nzLabel]="option.label"></nz-option>
                            </nz-select>
                          </ng-container>
                          <!-- datePicker -->
                          <ng-container *ngSwitchCase="'datePicker'">
                            <ng-container *ngIf="item.pickerType == 'range'">
                              <nz-range-picker
                                *ngIf="item.nzShowTime"
                                [ngModel]="item.defaultValue"
                                [nzPlaceHolder]="getPropertyObj(item, 'placeholder')"
                                [ngModelOptions]="{ standalone: true }"
                                nzAllowClear
                                [nzSize]="config.nzSize"
                                nzShowTime
                              ></nz-range-picker>
                              <nz-range-picker
                                *ngIf="!item.nzShowTime"
                                [ngModel]="item.defaultValue"
                                [nzPlaceHolder]="getPropertyObj(item, 'placeholder')"
                                [ngModelOptions]="{ standalone: true }"
                                nzAllowClear
                                [nzSize]="config.nzSize"
                              >
                              </nz-range-picker>
                            </ng-container>
                            <ng-container *ngIf="item.pickerType != 'range'">
                              <nz-date-picker
                                *ngIf="item.nzShowTime"
                                [ngModel]="item.defaultValue"
                                [nzPlaceHolder]="item.placeholder"
                                [ngModelOptions]="{ standalone: true }"
                                nzAllowClear
                                [nzSize]="config.nzSize"
                                [nzMode]="item.pickerType"
                                nzShowTime
                              ></nz-date-picker>
                              <nz-date-picker
                                *ngIf="!item.nzShowTime"
                                [ngModel]="item.defaultValue"
                                [nzPlaceHolder]="item.placeholder"
                                [ngModelOptions]="{ standalone: true }"
                                nzAllowClear
                                [nzSize]="config.nzSize"
                                [nzMode]="item.pickerType"
                              ></nz-date-picker>
                            </ng-container>
                          </ng-container>
                          <!-- timePicker -->
                          <ng-container *ngSwitchCase="'timePicker'">
                            <nz-time-picker
                              [ngModel]="item.defaultValue"
                              [nzPlaceHolder]="item.placeholder"
                              [ngModelOptions]="{ standalone: true }"
                              [nzFormat]="item.nzFormat"
                              nzAllowEmpty
                              [nzSize]="config.nzSize"
                            ></nz-time-picker>
                          </ng-container>
                        </ng-container>
                      </nz-form-control>
                    </nz-form-item>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-template>

            <!-- 表单项控制模板 -->
            <ng-template #form_item_control let-item="item" let-parentNode="parentNode">
              <div class="control">
                <div (click)="$event.stopPropagation()" (mousedown)="copy.hide(); remove.hide()">
                  <span
                    nz-tooltip
                    nzTooltipTitle="移动"
                    #move="nzTooltip"
                    (mousedown)="move.hide(); onMousedown($event)"
                    (mouseup)="onMouseup($event)"
                    ><i nz-icon nzType="drag" nzTheme="outline"></i
                  ></span>
                  <span nz-tooltip nzTooltipTitle="复制" #copy="nzTooltip" (click)="copyNode(item, parentNode)"
                    ><i nz-icon nzType="copy" nzTheme="outline"></i
                  ></span>
                  <span nz-tooltip nzTooltipTitle="移除" #remove="nzTooltip" (click)="removeNode(item, parentNode)"
                    ><i nz-icon nzType="close" nzTheme="outline"></i
                  ></span>
                </div>
              </div>
            </ng-template>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="flexNone property" style="padding-top: 8px">
    <nz-tabset [(nzSelectedIndex)]="propertyTabSelectedIndex">
      <nz-tab nzTitle="控件属性">
        <div class="tab_container" *ngIf="activeNode">
          <!-- 简介 -->
          <div class="intro" *ngIf="introMap[activeNode.type]" [innerHTML]="introMap[activeNode.type]"></div>

          <ng-container
            *ngIf="['input', 'textarea', 'radio', 'checkbox', 'switch', 'select', 'datePicker', 'timePicker'].includes(activeNode.type)"
          >
            <!-- 基本属性 -->
            <fieldset class="fieldset_sm baseProperty">
              <legend>基本属性</legend>
              <div>
                <nz-input-group [nzAddOnBefore]="'标签名称'" class="nz_input_group_required">
                  <input type="text" nz-input [(ngModel)]="activeNode.label" (ngModelChange)="validNodeConfig(activeNode)" />
                </nz-input-group>
                <nz-input-group [nzAddOnBefore]="'字段标识'" class="nz_input_group_required">
                  <input
                    type="text"
                    disabled
                    *ngIf="!(activeNode.type == 'datePicker' && activeNode.pickerType == 'range')"
                    nz-input
                    [(ngModel)]="activeNode.model"
                    (ngModelChange)="validNodeConfig(activeNode)"
                  />
                  <ng-container *ngIf="activeNode.type == 'datePicker' && activeNode.pickerType == 'range'">
                    <input
                      type="text"
                      nz-input
                      placeholder="开始"
                      [(ngModel)]="activeNode.startModel"
                      (ngModelChange)="validNodeConfig(activeNode)"
                      style="width: 45%; text-align: center; border-right: 0"
                    />
                    <input
                      type="text"
                      disabled
                      nz-input
                      placeholder="~"
                      style="
                        width: 10%;
                        border-left: 0px;
                        pointer-events: none;
                        background-color: rgb(255, 255, 255);
                        padding: 4px 0;
                        border-width: 1px 0;
                        text-align: center;
                      "
                    />
                    <input
                      type="text"
                      nz-input
                      placeholder="结束"
                      [(ngModel)]="activeNode.endModel"
                      (ngModelChange)="validNodeConfig(activeNode)"
                      style="width: 45%; text-align: center; border-left: 0px"
                    />
                  </ng-container>
                </nz-input-group>
                <nz-input-group
                  nzAddOnBefore="占位提示"
                  *ngIf="
                    ['input', 'textarea', 'datePicker', 'timePicker', 'select'].includes(activeNode.type) &&
                    activeNode.pickerType != 'range'
                  "
                >
                  <input type="text" nz-input [(ngModel)]="activeNode.placeholder" placeholder="非必填" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="占位提示" *ngIf="activeNode.pickerType == 'range'">
                  <input
                    type="text"
                    nz-input
                    placeholder="开始"
                    [(ngModel)]="activeNode.placeholder[0]"
                    style="width: 45%; text-align: center; border-right: 0"
                  />
                  <input
                    type="text"
                    disabled
                    nz-input
                    placeholder="~"
                    style="
                      width: 10%;
                      border-left: 0px;
                      pointer-events: none;
                      background-color: rgb(255, 255, 255);
                      padding: 4px 0;
                      border-width: 1px 0;
                      text-align: center;
                    "
                  />
                  <input
                    type="text"
                    nz-input
                    placeholder="结束"
                    [(ngModel)]="activeNode.placeholder[1]"
                    style="width: 45%; text-align: center; border-left: 0px"
                  />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="默认值" *ngIf="['input', 'textarea'].includes(activeNode.type)">
                  <input type="text" nz-input [(ngModel)]="activeNode.defaultValue" placeholder="非必填" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="默认值" *ngIf="activeNode.type == 'switch'">
                  <div class="input-group-switch">
                    <nz-switch [(ngModel)]="activeNode.defaultValue"></nz-switch>
                  </div>
                </nz-input-group>
                <nz-input-group
                  nzAddOnBefore="标签宽度"
                  nzAddOnAfter="PX"
                  *ngIf="config.layout == 'horizontal' || (config.layout == 'inline' && !config.inlineVertical)"
                >
                  <nz-input-number
                    [(ngModel)]="activeNode.labelWidth"
                    [nzMin]="50"
                    [nzMax]="400"
                    [nzStep]="16"
                    nzPlaceHolder="非必填"
                  ></nz-input-number>
                </nz-input-group>
                <nz-input-group nzAddOnBefore="控件宽度" nzAddOnAfter="PX" *ngIf="config.layout == 'inline'">
                  <nz-input-number
                    *ngIf="activeNode.type != 'datePicker'"
                    [(ngModel)]="activeNode.controlWidth"
                    [nzMin]="50"
                    [nzMax]="400"
                    [nzStep]="16"
                    nzPlaceHolder="非必填"
                  ></nz-input-number>
                  <nz-input-number
                    *ngIf="activeNode.type == 'datePicker'"
                    [(ngModel)]="activeNode.controlWidth[activeNode.pickerType + (activeNode.nzShowTime ? 'Time' : '')]"
                    [nzMin]="50"
                    [nzMax]="400"
                    [nzStep]="16"
                    nzPlaceHolder="非必填"
                  ></nz-input-number>
                </nz-input-group>
              </div>
            </fieldset>

            <!-- 校验 -->
            <fieldset class="fieldset_sm" *ngIf="activeNode.type != 'switch'">
              <legend>校验</legend>
              <div>
                <label nz-checkbox [(ngModel)]="activeNode.required">必填</label>
              </div>
            </fieldset>
          </ng-container>

          <!-- input -->
          <ng-container *ngIf="activeNode.type == 'input'">
            <fieldset class="fieldset_sm">
              <legend>类型</legend>
              <div>
                <nz-radio-group [(ngModel)]="activeNode.inputType" [nzButtonStyle]="'solid'">
                  <label nz-radio-button nzValue="text">文本</label>
                  <label nz-radio-button nzValue="number">数值</label>
                  <label nz-radio-button nzValue="password">密码</label>
                </nz-radio-group>
              </div>
            </fieldset>
            <!-- <nz-input-group nzAddOnBefore="前缀">
              <input type="text" nz-input [(ngModel)]="activeNode.nzAddOnBefore" placeholder="非必填" />
            </nz-input-group>
            <nz-input-group nzAddOnBefore="后缀">
              <input type="text" nz-input [(ngModel)]="activeNode.nzAddOnAfter" placeholder="非必填" />
            </nz-input-group> -->
          </ng-container>
          <!-- textarea -->
          <ng-container *ngIf="activeNode.type == 'textarea'">
            <nz-input-group nzAddOnBefore="最小行数">
              <nz-input-number
                [(ngModel)]="activeNode.nzAutosize.minRows"
                [nzMin]="2"
                [nzMax]="20"
                [nzStep]="1"
                nzPlaceHolder="非必填"
              ></nz-input-number>
            </nz-input-group>
            <nz-input-group nzAddOnBefore="最大行数">
              <nz-input-number
                [(ngModel)]="activeNode.nzAutosize.maxRows"
                [nzMin]="2"
                [nzMax]="20"
                [nzStep]="1"
                nzPlaceHolder="非必填"
              ></nz-input-number>
            </nz-input-group>
          </ng-container>
          <!-- 'radio', 'checkbox', 'select' -->
          <ng-container *ngIf="['radio', 'checkbox', 'select'].includes(activeNode.type)">
            <!-- <fieldset class="fieldset_sm" *ngIf="['radio', 'checkbox'].includes(activeNode.type)">
              <legend>样式</legend>
              <div>
                <div *ngIf="activeNode.type == 'radio'">
                  <label nz-checkbox [(ngModel)]="activeNode.buttonStyle"
                    (ngModelChange)="$event && (activeNode.layout = 'horizontal')">按钮风格</label>
                </div>
                <nz-radio-group *ngIf="!activeNode.buttonStyle"
                  [style.margin-top]="activeNode.type == 'radio' ? '8px' : 0" [(ngModel)]="activeNode.layout"
                  [nzButtonStyle]="'solid'">
                  <label nz-radio-button nzValue="horizontal">水平排列</label>
                  <label nz-radio-button nzValue="vertical">垂直排列</label>
                </nz-radio-group>
              </div>
            </fieldset> -->
            <fieldset class="fieldset_sm" *ngIf="activeNode.type == 'select'">
              <legend>类型</legend>
              <div>
                <nz-radio-group
                  [(ngModel)]="activeNode.nzMode"
                  (ngModelChange)="activeNode.defaultValue = $event == 'default' ? null : []"
                  [nzButtonStyle]="'solid'"
                >
                  <label nz-radio-button nzValue="default">单选</label>
                  <label nz-radio-button nzValue="multiple">多选</label>
                  <label nz-radio-button nzValue="tags">标签输入</label>
                </nz-radio-group>
              </div>
            </fieldset>
            <fieldset class="fieldset_sm">
              <!-- <legend>选项来源</legend> -->
              <div>
                <!-- <nz-radio-group [(ngModel)]="activeNode.optionSourceType" [nzButtonStyle]="'solid'"
                  style="margin-bottom: 8px">
                  <label nz-radio-button nzValue="manual">手动添加</label>
                  <label nz-radio-button nzValue="native">本地数据</label>
                  <label nz-radio-button nzValue="server">服务接口</label>
                </nz-radio-group> -->
                <!-- 手动添加 -->
                <ng-container *ngIf="activeNode.optionSourceType == 'manual'">
                  <div class="option_config">
                    <div class="config__head">
                      <div><span style="color: red">* </span>标签</div>
                      <div><span style="color: red">* </span>值</div>
                      <div style="flex: 0 0 30px">选中</div>
                      <div style="flex: 0 0 50px">+/-</div>
                    </div>
                    <div class="config_body" *ngFor="let option of activeNode.options || []; let i = index">
                      <div>
                        <!-- <input type="text" nzSize="small" nz-input [(ngModel)]="option.label"
                          (ngModelChange)="option.value = option.label" placeholder="" /> -->
                        <input type="text" nzSize="small" nz-input [(ngModel)]="option.label" placeholder="" />
                      </div>
                      <div>
                        <input disabled="disabled" type="text" nzSize="small" nz-input [(ngModel)]="option.value" placeholder="" />
                      </div>
                      <div style="flex: 0 0 30px; text-align: center">
                        <!-- radio -->
                        <ng-container *ngIf="activeNode.type == 'radio'">
                          <label
                            nz-checkbox
                            [ngModel]="activeNode.defaultValue == option.value"
                            (ngModelChange)="activeNode.defaultValue = $event ? option.value : null"
                          ></label>
                        </ng-container>
                        <!-- checkbox -->
                        <ng-container *ngIf="activeNode.type == 'checkbox'">
                          <label nz-checkbox [(ngModel)]="option.checked"></label>
                        </ng-container>
                        <!-- select -->
                        <ng-container *ngIf="activeNode.type == 'select'">
                          <!-- 单选 -->
                          <ng-container *ngIf="activeNode.nzMode == 'default'">
                            <label
                              nz-checkbox
                              [ngModel]="activeNode.defaultValue == option.value"
                              (ngModelChange)="activeNode.defaultValue = $event ? option.value : null"
                            ></label>
                          </ng-container>
                          <!-- 多选 -->
                          <ng-container *ngIf="activeNode.nzMode != 'default'">
                            <label
                              nz-checkbox
                              [ngModel]="activeNode.defaultValue.includes(option.value)"
                              (ngModelChange)="selectOptionCheckboxChange($event, option)"
                            ></label>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div style="flex: 0 0 50px; text-align: center">
                        <!-- (click)="activeNode.options.splice(i + 1, 0, { label: '选项' + (i + 2), value: 'option' + (i + 2) })" -->
                        <i nz-icon class="plus" nzType="plus-circle" nzTheme="outline" (click)="clickOptions(i)"></i>
                        <i
                          nz-icon
                          class="minus"
                          nzType="minus-circle"
                          nzTheme="outline"
                          (click)="activeNode.options.splice(i, 1); validNodeConfig(activeNode)"
                        ></i>
                      </div>
                    </div>
                    <div *ngIf="(activeNode.options || []).length == 0">
                      <button
                        nz-button
                        nzType="dashed"
                        nzSize="small"
                        nzBlock
                        (click)="activeNode.options.splice(0, 0, { label: 'option1', value: 'option1' }); validNodeConfig(activeNode)"
                      >
                        <i nz-icon class="plus" nzType="plus-circle" nzTheme="outline"></i>
                        <span>添加</span>
                      </button>
                    </div>
                  </div>
                </ng-container>
                <!-- 本地数据 -->
                <ng-container *ngIf="activeNode.optionSourceType == 'native'">
                  <div class="intro">由开发人员填写</div>
                  <div class="nativeSourceProperty">
                    <nz-input-group [nzAddOnBefore]="'数据源标识'" class="nz_input_group_required">
                      <input
                        type="text"
                        nz-input
                        [(ngModel)]="activeNode.nativeSourceField"
                        (ngModelChange)="validNodeConfig(activeNode)"
                        placeholder=""
                      />
                    </nz-input-group>
                    <nz-input-group [nzAddOnBefore]="'标签字段'" class="nz_input_group_required">
                      <input
                        type="text"
                        nz-input
                        [(ngModel)]="activeNode.labelField"
                        (ngModelChange)="validNodeConfig(activeNode)"
                        placeholder="数据源中标签的字段"
                      />
                    </nz-input-group>
                    <nz-input-group [nzAddOnBefore]="'值字段'" class="nz_input_group_required">
                      <input
                        type="text"
                        nz-input
                        [(ngModel)]="activeNode.valueField"
                        (ngModelChange)="validNodeConfig(activeNode)"
                        placeholder="数据源中值的字段"
                      />
                    </nz-input-group>
                  </div>
                </ng-container>
                <!-- 服务接口 -->
                <ng-container *ngIf="activeNode.optionSourceType == 'server'">
                  <div class="intro">由开发人员填写</div>
                  <div class="nativeSourceProperty">
                    <nz-input-group [nzAddOnBefore]="'接口地址'" class="nz_input_group_required">
                      <input
                        type="text"
                        nz-input
                        [(ngModel)]="activeNode.url"
                        (ngModelChange)="validNodeConfig(activeNode)"
                        placeholder="完整url或相对于baseUrl"
                      />
                    </nz-input-group>
                    <nz-input-group [nzAddOnBefore]="'请求方式'" class="nz_input_group_required">
                      <nz-select [(ngModel)]="activeNode.method">
                        <nz-option nzValue="get" nzLabel="GET"></nz-option>
                        <nz-option nzValue="post_json" nzLabel="POST(JSON)"></nz-option>
                        <nz-option nzValue="post_form" nzLabel="POST(FORM)"></nz-option>
                      </nz-select>
                    </nz-input-group>
                    <nz-input-group
                      [nzAddOnBefore]="'数据源路径'"
                      nz-popover
                      [nzPopoverContent]="serverSourceField_title"
                      nzPopoverPlacement="left"
                      class="nz_input_group_required"
                    >
                      <ng-template #serverSourceField_title>
                        列表字段路径 或 匿名函数(返回数组)<br />
                        * 字段路径: data.data<br />
                        * 匿名函数示例: (res) => res.data.data || []
                      </ng-template>
                      <input
                        type="text"
                        nz-input
                        [(ngModel)]="activeNode.serverSourceField"
                        (ngModelChange)="validNodeConfig(activeNode)"
                        placeholder="字段路径 或 匿名函数"
                      />
                    </nz-input-group>
                    <nz-input-group [nzAddOnBefore]="'标签字段'" class="nz_input_group_required">
                      <input
                        type="text"
                        nz-input
                        [(ngModel)]="activeNode.labelField"
                        (ngModelChange)="validNodeConfig(activeNode)"
                        placeholder="数据源中标签的字段"
                      />
                    </nz-input-group>
                    <nz-input-group [nzAddOnBefore]="'值字段'" class="nz_input_group_required">
                      <input
                        type="text"
                        nz-input
                        [(ngModel)]="activeNode.valueField"
                        (ngModelChange)="validNodeConfig(activeNode)"
                        placeholder="数据源中值的字段"
                      />
                    </nz-input-group>
                  </div>
                </ng-container>
              </div>
            </fieldset>
          </ng-container>
          <!-- switch -->
          <ng-container *ngIf="activeNode.type == 'switch'">
            <fieldset class="fieldset_sm switchProperty">
              <legend>状态文案</legend>
              <div>
                <nz-input-group nzAddOnBefore="选中时">
                  <input type="text" nz-input [(ngModel)]="activeNode.nzCheckedChildren" placeholder="非必填" />
                </nz-input-group>
                <nz-input-group nzAddOnBefore="非选中时">
                  <input type="text" nz-input [(ngModel)]="activeNode.nzUnCheckedChildren" placeholder="非必填" />
                </nz-input-group>
              </div>
            </fieldset>
          </ng-container>
          <!-- datePicker -->
          <ng-container *ngIf="activeNode.type == 'datePicker'">
            <fieldset class="fieldset_sm">
              <legend>类型</legend>
              <div>
                <nz-radio-group
                  [(ngModel)]="activeNode.pickerType"
                  (ngModelChange)="activeNode.placeholder = null; initNodes([activeNode])"
                  [nzButtonStyle]="'solid'"
                >
                  <label nz-radio-button nzValue="date">日</label>
                  <label nz-radio-button nzValue="week">周</label>
                  <label nz-radio-button nzValue="month">月</label>
                  <label nz-radio-button nzValue="year">年</label>
                  <label nz-radio-button nzValue="range">范围</label>
                </nz-radio-group>
                <div *ngIf="['date', 'range'].includes(activeNode.pickerType)" style="margin-top: 8px">
                  <label nz-checkbox [(ngModel)]="activeNode.nzShowTime" (ngModelChange)="setPickerDefaultValue(activeNode)"
                    >增加时间选择功能</label
                  >
                </div>
              </div>
            </fieldset>
            <fieldset class="fieldset_sm" *ngIf="['date', 'range'].includes(activeNode.pickerType)">
              <legend>取值类型</legend>
              <div>
                <nz-radio-group [(ngModel)]="activeNode.valueType" [nzButtonStyle]="'solid'">
                  <label nz-radio-button nzValue="timestamp" nz-tooltip nzTooltipTitle="毫秒数值" style="padding: 0 8px">时间戳</label>
                  <label nz-radio-button nzValue="ISO8601T" nz-tooltip nzTooltipTitle="yyyy-MM-ddTHH:mm:ss" style="padding: 0 8px"
                    >ISO8601</label
                  >
                  <label nz-radio-button nzValue="ISO8601" nz-tooltip nzTooltipTitle="yyyy-MM-dd HH:mm:ss" style="padding: 0 8px"
                    >ISO8601(空格分隔)</label
                  >
                </nz-radio-group>
              </div>
            </fieldset>
            <label nz-checkbox [(ngModel)]="activeNode.now" (ngModelChange)="setPickerDefaultValue(activeNode)">设置默认值为当前时间</label>
          </ng-container>
          <!-- timePicker -->
          <ng-container *ngIf="activeNode.type == 'timePicker'">
            <fieldset class="fieldset_sm">
              <legend>类型</legend>
              <div>
                <nz-radio-group
                  [(ngModel)]="activeNode.nzFormat"
                  (ngModelChange)="setPickerDefaultValue(activeNode)"
                  [nzButtonStyle]="'solid'"
                >
                  <label nz-radio-button nzValue="HH:mm:ss">时分秒</label>
                  <label nz-radio-button nzValue="HH:mm">时分</label>
                </nz-radio-group>
              </div>
            </fieldset>
            <label nz-checkbox [(ngModel)]="activeNode.now" (ngModelChange)="setPickerDefaultValue(activeNode)">设置默认值为当前时间</label>
          </ng-container>
          <!-- row -->
          <ng-container *ngIf="activeNode.type == 'row'">
            <nz-input-group nzAddOnBefore="栅格间隔" nzAddOnAfter="PX" style="margin-bottom: 8px">
              <nz-input-number [(ngModel)]="activeNode.nzGutter" [nzMin]="0" [nzStep]="8" nzPlaceHolder="非必填"> </nz-input-number>
            </nz-input-group>
            <button
              nz-button
              nzType="primary"
              nzGhost
              nzBlock
              (click)="activeNode.children.push({ type: 'col', nzSpan: 12 }); bindDragover()"
            >
              <i nz-icon class="plus" nzType="plus-circle" nzTheme="outline"></i>
              <span>添加一列</span>
            </button>
          </ng-container>
          <!-- col -->
          <ng-container *ngIf="activeNode.type == 'col'">
            <nz-input-group nzAddOnBefore="列宽" nzAddOnAfter="≤24>" style="margin-bottom: 8px">
              <nz-input-number
                [(ngModel)]="activeNode.nzSpan"
                [nzMin]="4"
                [nzMax]="24"
                [nzStep]="4"
                nzPlaceHolder="小于等于24"
              ></nz-input-number>
            </nz-input-group>
            <fieldset class="fieldset_sm" *ngIf="config.layout == 'horizontal'">
              <legend>表单项宽度</legend>
              <div>
                <nz-input-group nzAddOnBefore="标签宽度">
                  <nz-input-number
                    [(ngModel)]="activeNode.labelNzSpan"
                    (ngModelChange)="activeNode.controlNzSpan = 24 - activeNode.labelNzSpan"
                    [nzMin]="2"
                    [nzMax]="22"
                    [nzStep]="1"
                    nzPlaceHolder=""
                  ></nz-input-number>
                </nz-input-group>
                <nz-input-group nzAddOnBefore="控件宽度">
                  <nz-input-number
                    [(ngModel)]="activeNode.controlNzSpan"
                    (ngModelChange)="activeNode.labelNzSpan = 24 - activeNode.controlNzSpan"
                    [nzMin]="2"
                    [nzMax]="22"
                    [nzStep]="1"
                    nzPlaceHolder=""
                  ></nz-input-number>
                </nz-input-group>
              </div>
            </fieldset>
          </ng-container>
          <!-- hr -->
          <ng-container *ngIf="activeNode.type == 'hr'">
            <nz-input-group nzAddOnBefore="上下间距" nzAddOnAfter="PX" style="margin-bottom: 8px">
              <nz-input-number
                [(ngModel)]="activeNode.margin"
                [nzMin]="16"
                [nzMax]="400"
                [nzStep]="4"
                nzPlaceHolder="非必填"
              ></nz-input-number>
            </nz-input-group>
            <label nz-checkbox [(ngModel)]="activeNode.hidden">隐藏分割线(保留占位)</label>
          </ng-container>
          <!-- template -->
          <ng-container *ngIf="activeNode.type == 'template'">
            <nz-input-group [nzAddOnBefore]="'Key'" class="nz_input_group_required">
              <input
                type="text"
                nz-input
                [(ngModel)]="activeNode.key"
                (ngModelChange)="validNodeConfig(activeNode)"
                placeholder="用于获取templateRef"
              />
            </nz-input-group>
          </ng-container>
          <!-- 站点 -->
          <ng-container *ngIf="activeNode.type == 'station'">
            <nz-input-group [nzAddOnBefore]="'组件高度'" class="nzaddonbefore">
              <nz-input-number [(ngModel)]="activeNode.height" [nzMin]="20" [nzStep]="1" nzPlaceHolder=""></nz-input-number>
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'组件背景'" class="nzaddonbefore">
              <input nz-input type="color" style="width: 100px" [(ngModel)]="activeNode.backgroundColor" />
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'站点颜色'" class="nzaddonbefore">
              <input nz-input type="color" style="width: 100px" [(ngModel)]="activeNode.fontColor" />
            </nz-input-group>
          </ng-container>
          <!-- 图片 -->
          <ng-container *ngIf="activeNode.type == 'img'">
            <div nz-row class="d-flex align-items-center mt-md mb-md">
              <div nz-col nzSpan="7" class="text-center">选择图片:</div>
              <div nz-col nzSpan="17">
                <button (click)="addImg()" nz-button nzType="default" nzShape="round">
                  <span nz-icon nzType="download"></span>
                  请选择
                </button>
              </div>
            </div>

            <nz-input-group [nzAddOnBefore]="'组件高度'" class="nzaddonbefore">
              <nz-input-number
                [(ngModel)]="activeNode.height"
                (ngModelChange)="onVideoHeightChange($event)"
                [nzMin]="20"
                [nzStep]="1"
                nzPlaceHolder=""
              ></nz-input-number>
            </nz-input-group>

            <nz-input-group [nzAddOnBefore]="'切换动画'">
              <nz-select [(ngModel)]="activeNode.strategy" style="width: 100px">
                <nz-option nzValue="transform-no-loop" nzLabel="平滑"></nz-option>
                <!-- <nz-option nzValue="flip" nzLabel="翻转"></nz-option>
                <nz-option nzValue="fade" nzLabel="淡入淡出"></nz-option> -->
              </nz-select>
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'切换时间(秒)'" class="nzaddonbefore">
              <nz-input-number [(ngModel)]="activeNode.scrollSpeed" [nzMin]="0" [nzStep]="1" nzPlaceHolder=""></nz-input-number>
            </nz-input-group>
            <nz-table #smallTable nzSize="small" [nzShowPagination]="false" [nzData]="activeNode.carouselItems">
              <thead>
                <tr>
                  <th nzWidth="100px">名称</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of smallTable.data">
                  <td [title]="data.source_material_name">
                    <div class="text-truncate" style="width: 180px">
                      {{ data.source_material_name || data.id + ' - 匿名图片' }}
                    </div>
                  </td>
                  <td
                    ><span nz-icon nzType="form" class="cursor-pointer ml-xs" title="查看" (click)="read(data)" nzTheme="outline"></span
                    ><span
                      nz-icon
                      nzType="delete"
                      class="cursor-pointer ml-xs"
                      title="删除"
                      (click)="delete(data)"
                      nzTheme="outline"
                    ></span>
                    <!-- <span
                      nz-icon
                      nzType="edit"
                      nzTheme="outline"
                      class="cursor-pointer ml-xs"
                      title="更换"
                      (click)="updateimg(data)"
                    ></span
                  > -->
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </ng-container>
          <!-- 视频 -->
          <ng-container *ngIf="activeNode.type == 'video'">
            <div>
              <div nz-row class="d-flex align-items-center mb-md">
                <div nz-col nzSpan="7" class="text-center">选择视频:</div>
                <div nz-col nzSpan="17">
                  <button (click)="addvideo()" nz-button nzType="default" nzShape="round">
                    <span nz-icon nzType="download"></span>
                    请选择
                  </button>
                </div>
              </div>
            </div>

            <nz-input-group [nzAddOnBefore]="'切换动画'">
              <nz-select [(ngModel)]="activeNode.strategy" style="width: 100px">
                <nz-option nzValue="transform-no-loop" nzLabel="平滑"></nz-option>
                <!-- <nz-option nzValue="flip" nzLabel="翻转"></nz-option>
                <nz-option nzValue="fade" nzLabel="淡入淡出"></nz-option> -->
              </nz-select>
            </nz-input-group>
            <div class="mt-md">
              <nz-input-group [nzAddOnBefore]="'组件高度'" class="nzaddonbefore">
                <nz-input-number
                  [(ngModel)]="activeNode.height"
                  (ngModelChange)="onVideoHeightChange($event)"
                  [nzMin]="20"
                  [nzStep]="1"
                  nzPlaceHolder=""
                ></nz-input-number>
              </nz-input-group>
            </div>
            <nz-table #smallTable1 nzSize="small" [nzShowPagination]="false" [nzData]="activeNode.carouselItems">
              <thead>
                <tr>
                  <th>名称</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of smallTable1.data">
                  <td [title]="data.source_material_name">
                    <div class="text-truncate" style="width: 180px">
                      {{ data.source_material_name || data.id + ' - 匿名视频' }}
                    </div>
                  </td>
                  <td
                    ><span nz-icon nzType="form" class="cursor-pointer ml-xs" title="查看" (click)="read(data)" nzTheme="outline"></span
                    ><span
                      nz-icon
                      nzType="delete"
                      class="cursor-pointer ml-xs"
                      title="删除"
                      (click)="delete(data)"
                      nzTheme="outline"
                    ></span>
                    <!-- <span nz-icon nzType="edit" nzTheme="outline" class="cursor-pointer ml-xs" title="更换" (click)="update(data)">
                      </span
                  > -->
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </ng-container>
          <!-- 滚动字幕 -->
          <ng-container *ngIf="activeNode.type == 'scroll-text'">
            <nz-input-group [nzAddOnBefore]="'组件高度'" class="nzaddonbefore">
              <nz-input-number [(ngModel)]="activeNode.height" [nzMin]="20" [nzStep]="1" nzPlaceHolder=""></nz-input-number>
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'组件背景'" class="nzaddonbefore">
              <input nz-input type="color" style="width: 100px" [(ngModel)]="activeNode.backgroundColor" />
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'文字颜色'" class="nzaddonbefore">
              <input nz-input type="color" style="width: 100px" [(ngModel)]="activeNode.fontColor" />
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'文字大小'" class="nzaddonbefore">
              <nz-input-number [(ngModel)]="activeNode.fontSize" [nzMin]="8" [nzStep]="1" nzPlaceHolder=""></nz-input-number>
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'滚动时间'" class="nzaddonbefore">
              <nz-input-number [(ngModel)]="activeNode.scrollSpeed" [nzMin]="10" [nzStep]="1" nzPlaceHolder=""></nz-input-number>
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'文字描述'" class="nzaddonbefore">
              <input nz-input type="text" [(ngModel)]="activeNode.text" />
            </nz-input-group>
          </ng-container>
          <!-- 日期天气 -->
          <ng-container *ngIf="activeNode.type == 'date-weather'">
            <nz-input-group [nzAddOnBefore]="'组件高度'" class="nzaddonbefore">
              <nz-input-number [(ngModel)]="activeNode.height" [nzMin]="20" [nzStep]="1" nzPlaceHolder=""></nz-input-number>
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'组件背景'" class="nzaddonbefore">
              <input nz-input type="color" style="width: 100px" [(ngModel)]="activeNode.backgroundColor" />
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'文字颜色'" class="nzaddonbefore">
              <input nz-input type="color" style="width: 100px" [(ngModel)]="activeNode.fontColor" />
            </nz-input-group>
            <nz-input-group [nzAddOnBefore]="'文字大小'" class="nzaddonbefore">
              <nz-input-number [(ngModel)]="activeNode.fontSize" [nzMin]="8" [nzStep]="1" nzPlaceHolder=""></nz-input-number>
            </nz-input-group>
          </ng-container>
        </div>
      </nz-tab>
      <nz-tab nzTitle="表单属性">
        <div class="tab_container">
          <fieldset class="fieldset_sm">
            <legend>背景音乐</legend>
            <div>
              <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                <input type="text" [readonly]="true" (dblclick)="onDblClick()" nz-input placeholder="请选择" [(ngModel)]="config.mp3" />
              </nz-input-group>
              <ng-template #suffixIconButton>
                <button nz-button nzType="default"><span nz-icon nzType="search" (click)="addvideo(3)"></span></button>
              </ng-template>
            </div>
          </fieldset>
          <fieldset class="fieldset_sm">
            <legend>设置当前节目播放时间</legend>
            <div>
              <nz-input-number [(ngModel)]="config.time" [nzMin]="0" [nzStep]="1" nzPlaceHolder=""></nz-input-number>
            </div>
          </fieldset>
          <fieldset class="fieldset_sm">
            <legend>设置当前节目在那个时间段播放</legend>
            <div>
              <nz-range-picker [(ngModel)]="config.date"></nz-range-picker>
            </div>
          </fieldset>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
</div>

<!-- JSON 编辑对话框 -->
<nz-modal
  [(nzVisible)]="jsonEditDialog.display"
  nzTitle="编辑配置"
  [nzContent]="jsonModalContent"
  (nzOnCancel)="jsonEditDialog.display = false"
  (nzOnOk)="jsonEditDialogOk()"
>
  <ng-template #jsonModalContent>
    <textarea nz-input [(ngModel)]="jsonEditDialog.value" [nzAutosize]="{ minRows: 2, maxRows: 25 }" style="min-height: 250px"></textarea>
  </ng-template>
</nz-modal>

<!-- HTML生成 对话框 -->
<nz-modal
  [(nzVisible)]="htmlDialog.display"
  nzTitle="Angular 响应式表单"
  [nzContent]="htmlModalContent"
  [nzWidth]="800"
  (nzOnCancel)="htmlDialog.display = false"
  [nzFooter]="null"
>
  <ng-template #htmlModalContent>
    <nz-tabset [(nzSelectedIndex)]="htmlDialog.tabSelectedIndex" [nzTabBarExtraContent]="tabsetextraTemplate" style="margin-top: -25px">
      <nz-tab nzTitle="HTML">
        <div class="htmlCodeContainer">{{ htmlDialog.html }}</div>
      </nz-tab>
      <nz-tab nzTitle="TypeScript">
        <div class="htmlCodeContainer">{{ htmlDialog.ts }}</div>
      </nz-tab>
    </nz-tabset>
    <ng-template #tabsetextraTemplate>
      <button nz-button nzType="primary" nzGhost nzSize="small" (click)="clipboardCopy()"
        ><i nz-icon nzType="copy" nzTheme="outline"></i> 复制</button
      >
    </ng-template>
  </ng-template>
</nz-modal>

<!-- 预览对话框 -->
<nz-modal
  [(nzVisible)]="previewDialog.display"
  nzTitle="预览"
  [nzContent]="previewModalContent"
  [nzWidth]="previewDialog.config.layout == 'inline' ? 1200 : previewDialog.config.containerWidth"
  (nzOnCancel)="previewDialog.display = false"
  [nzFooter]="null"
>
  <ng-template #previewModalContent>
    <x-form-render #XFormRender [config]="previewDialog.config" [formValue]="previewDialog.formValue" [baseUrl]="baseUrl"></x-form-render>
    <div style="background: #f5f5f6; padding: 10px 16px; text-align: right; margin: -24px; margin-top: 40px">
      <nz-button-group>
        <button nz-button nzType="default" (click)="XFormRender.disable()">禁用</button>
        <button nz-button nzType="default" (click)="XFormRender.enable()">启用</button>
      </nz-button-group>
      <nz-button-group style="margin: 0 8px">
        <button nz-button nzType="primary" nzGhost (click)="previewDialogSetValue(XFormRender)"
          ><i nz-icon nzType="form" nzTheme="outline"></i>取值/赋值</button
        >
      </nz-button-group>
      <nz-button-group>
        <button nz-button nzDanger nzType="primary" nzGhost (click)="XFormRender.reset()"
          ><i nz-icon nzType="reload" nzTheme="outline"></i>重置</button
        >
      </nz-button-group>
    </div>
  </ng-template>
</nz-modal>

<!-- 预览对话框 赋值 -->
<nz-modal
  [(nzVisible)]="previewDialog.setValueDialog.display"
  nzTitle="赋值"
  [nzContent]="fillModalContent"
  (nzOnCancel)="previewDialog.setValueDialog.display = false"
  (nzOnOk)="setValueDialogOk()"
>
  <ng-template #fillModalContent>
    <textarea
      nz-input
      [(ngModel)]="previewDialog.setValueDialog.value"
      [nzAutosize]="{ minRows: 2, maxRows: 25 }"
      style="min-height: 250px"
    ></textarea>
  </ng-template>
</nz-modal>
