<nz-spin [nzSpinning]="loading">
  <form nz-form *ngIf="formGroup" [formGroup]="formGroup" [nzLayout]="config.layout"
    [class.x-form-inline-vertical]="config.layout == 'inline' && config.inlineVertical" style="margin-bottom: -16px">
    <ng-container *ngTemplateOutlet="componentTpl; context: { $implicit: config }"></ng-container>
    <!-- 表单循环模板 -->
    <ng-template #componentTpl let-node>
      <ng-container *ngFor="let item of node.children || []">
        <ng-container [ngSwitch]="item.type">
          <!-- 分割线 -->
          <ng-container *ngSwitchCase="'hr'">
            <div style="overflow: hidden; margin-top: -16px">
              <hr [class.x-form-item-hidden]="item.hidden" [style.margin-top.px]="item.margin"
                [style.margin-bottom.px]="item.margin" />
            </div>
          </ng-container>
          <!-- 模板占位符 -->
          <ng-container *ngSwitchCase="'template'">
            <ng-container [ngTemplateOutlet]="(templateMap || {})[item.key]"
              [ngTemplateOutletContext]="{ $implicit: formGroup, node: item }"></ng-container>
          </ng-container>
          <!-- 栅格 -->
          <ng-container *ngSwitchCase="'row'">
            <div nz-row [nzGutter]="item.nzGutter">
              <div nz-col [nzSpan]="col.nzSpan" *ngFor="let col of item.children || []">
                <ng-container *ngTemplateOutlet="componentTpl; context: { $implicit: col }"></ng-container>
              </div>
            </div>
          </ng-container>
          <!-- 图片 -->
          <ng-container *ngSwitchCase="'img'">
            <div [style.height.px]="item.height">
              <app-x-form-swiper-img [xFormAutoPlaySpeed]="item.scrollSpeed" [strategy]="item.strategy"
                [items]="item.carouselItems"></app-x-form-swiper-img>
            </div>
          </ng-container>
          <!-- 视频 -->
          <ng-container *ngSwitchCase="'video'">
            <div [style.height.px]="item.height">
              <app-x-form-swiper-video [height]="item.height" [strategy]="item.strategy" [items]="item.carouselItems"
                isdesigh="1"></app-x-form-swiper-video>
            </div>
          </ng-container>
          <!-- 滚动字幕 -->
          <ng-container *ngSwitchCase="'scroll-text'">
            <div [style.height.px]="item.height">
              <app-scroll-text [backgroundColor]="item.backgroundColor" [fontColor]="item.fontColor"
                [fontSize]="item.fontSize + 'px'" [text]="item.text"
                [scrollSpeed]="item.scrollSpeed + 's'"></app-scroll-text>
            </div>
          </ng-container>
          <!-- 日期天气 -->
          <ng-container *ngSwitchCase="'date-weather'">
            <div [style.height.px]="item.height" style="width: 100%">
              <app-x-form-date-weather [backgroundColor]="item.backgroundColor" [fontColor]="item.fontColor"
                [fontSize]="item.fontSize + 'px'"></app-x-form-date-weather>
            </div>
          </ng-container>
          <!-- 日期天气 -->
          <ng-container *ngSwitchCase="'station'">
            <div [style.height.px]="item.height" style="width: 100%">
              <app-x-form-progress [backgroundColor]="item.backgroundColor"
                [fontColor]="item.fontColor"></app-x-form-progress>
            </div>
          </ng-container>
          <!-- 表单组件 -->
          <ng-container *ngSwitchDefault>
            <nz-form-item *ngIf="item.model">
              <nz-form-label
                [nzSpan]="config.layout == 'horizontal' && !item.labelWidth ? node.labelNzSpan || config.labelNzSpan : null"
                [nzRequired]="item.required"
                [class.x-form-vertical-align-top]="item.type == 'textarea' || item.layout == 'vertical'"
                [style.width.px]="item.labelWidth || null">{{ item.label }}</nz-form-label>
              <nz-form-control
                [nzSpan]="config.layout == 'horizontal' && !item.labelWidth ? node.controlNzSpan || config.controlNzSpan : null"
                [style.width.px]="xFormService.getComponentWidth(item, config)">
                <ng-container [ngSwitch]="item.type">
                  <!-- input -->
                  <ng-container *ngSwitchCase="'input'">
                    <ng-container *ngIf="!(item.nzAddOnBefore || item.nzAddOnAfter)">
                      <input nz-input [attr.type]="item.inputType" [formControlName]="item.model"
                        [attr.placeholder]="item.placeholder" [nzSize]="config.nzSize" />
                    </ng-container>
                    <ng-container *ngIf="item.nzAddOnBefore || item.nzAddOnAfter">
                      <nz-input-group [nzAddOnBefore]="item.nzAddOnBefore" [nzAddOnAfter]="item.nzAddOnAfter"
                        [nzSize]="config.nzSize">
                        <input nz-input [attr.type]="item.inputType" [formControlName]="item.model"
                          [attr.placeholder]="item.placeholder" />
                      </nz-input-group>
                    </ng-container>
                  </ng-container>
                  <!-- textarea -->
                  <ng-container *ngSwitchCase="'textarea'">
                    <textarea nz-input [formControlName]="item.model" [nzAutosize]="item.nzAutosize"
                      [attr.placeholder]="item.placeholder" [nzSize]="config.nzSize"></textarea>
                  </ng-container>
                  <!-- radio -->
                  <ng-container *ngSwitchCase="'radio'">
                    <nz-radio-group nzButtonStyle="solid" [formControlName]="item.model"
                      [class.x-form-option-vertical]="item.layout == 'vertical'" [nzSize]="config.nzSize">
                      <ng-container *ngIf="!item.buttonStyle">
                        <label nz-radio *ngFor="let option of item.options || []" [nzValue]="option.value">{{
                          option.label }}</label>
                      </ng-container>
                      <ng-container *ngIf="item.buttonStyle">
                        <label nz-radio-button *ngFor="let option of item.options || []" [nzValue]="option.value">{{
                          option.label }}</label>
                      </ng-container>
                    </nz-radio-group>
                  </ng-container>
                  <!-- checkbox -->
                  <ng-container *ngSwitchCase="'checkbox'">
                    <nz-checkbox-group [formControlName]="item.model"
                      [class.x-form-option-vertical]="item.layout == 'vertical'"></nz-checkbox-group>
                  </ng-container>
                  <!-- switch -->
                  <ng-container *ngSwitchCase="'switch'">
                    <nz-switch [formControlName]="item.model" [nzCheckedChildren]="item.nzCheckedChildren"
                      [nzUnCheckedChildren]="item.nzUnCheckedChildren" [nzSize]="config.nzSize"></nz-switch>
                  </ng-container>
                  <!-- select -->
                  <ng-container *ngSwitchCase="'select'">
                    <nz-select [formControlName]="item.model" [nzMode]="item.nzMode" nzAllowClear nzShowSearch
                      [nzPlaceHolder]="item.placeholder" [nzDropdownMatchSelectWidth]="false" [nzSize]="config.nzSize">
                      <nz-option *ngFor="let option of item.options" [nzValue]="option.value"
                        [nzLabel]="option.label"></nz-option>
                    </nz-select>
                  </ng-container>
                  <!-- datePicker -->
                  <ng-container *ngSwitchCase="'datePicker'">
                    <ng-container *ngIf="item.pickerType == 'range'">
                      <nz-range-picker *ngIf="item.nzShowTime" [formControlName]="item.model"
                        [nzPlaceHolder]="item.placeholder" nzAllowClear [nzSize]="config.nzSize"
                        nzShowTime></nz-range-picker>
                      <nz-range-picker *ngIf="!item.nzShowTime" [formControlName]="item.model"
                        [nzPlaceHolder]="item.placeholder" nzAllowClear [nzSize]="config.nzSize"></nz-range-picker>
                    </ng-container>
                    <ng-container *ngIf="item.pickerType != 'range'">
                      <nz-date-picker *ngIf="item.nzShowTime" [formControlName]="item.model"
                        [nzPlaceHolder]="item.placeholder" nzAllowClear [nzSize]="config.nzSize"
                        [nzMode]="item.pickerType" nzShowTime></nz-date-picker>
                      <nz-date-picker *ngIf="!item.nzShowTime" [formControlName]="item.model"
                        [nzPlaceHolder]="item.placeholder" nzAllowClear [nzSize]="config.nzSize"
                        [nzMode]="item.pickerType"></nz-date-picker>
                    </ng-container>
                  </ng-container>
                  <!-- timePicker -->
                  <ng-container *ngSwitchCase="'timePicker'">
                    <nz-time-picker [formControlName]="item.model" [nzPlaceHolder]="item.placeholder"
                      [nzFormat]="item.nzFormat" nzAllowClear [nzSize]="config.nzSize"></nz-time-picker>
                  </ng-container>
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </form>
</nz-spin>