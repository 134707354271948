import { Component, OnInit } from '@angular/core';
import { BaseIndexComponent } from 'src/app/shared/component/app/curd/base-index.component';
import { CompanySexIndexComponent } from '../../code/sex/index.component';
import { CompanyMaterialReadComponent } from './read.component';
import { CompanyMaterialSaveComponent } from './save.component';
import { CompanyMaterialUpdateComponent } from './update.component';
import { IEventEmitter } from 'src/app/shared/model/core.d';
import { STData } from '@delon/abc/st';
import { YzbDialogComponent } from 'src/app/shared/component/common/yzb-dialog.component';
import { CompanyMaterialGroupIndexComponent } from '../material-group/index.component';
@Component({
  selector: 'app-company-Material-index',
  templateUrl: './../../../../shared/component/app/curd/base-index.component.html'
})
export class CompanyMaterialIndexComponent extends BaseIndexComponent implements OnInit {
  override ngOnInit() {
    this.title = '素材管理';
    this.baseUri = 'company/v1/source_material';
    this.dictId = 1012;
    this.menuCode = 'MN0302';
    super.ngOnInit();
    this.dictComponent = {
      1011: CompanyMaterialGroupIndexComponent
    };
    this.readComponent = CompanyMaterialReadComponent;
    this.saveComponent = CompanyMaterialSaveComponent;
    this.updateComponent = CompanyMaterialUpdateComponent;
  }

  override onButtonClick($event: IEventEmitter) {
    const code = $event.data as string;
    const selectData = this.getSelectedData($event) as STData;
    switch (code) {
      case 'FN030211':
        YzbDialogComponent.open(
          this.injector,
          CompanyMaterialGroupIndexComponent,
          {
            title: `设置分组`
          },
          {
            isDialog: false
          },
          {
            autoClose: false
          }
        ).subscribe(data => {});
        break;
      default:
        super.onButtonClick($event);
        break;
    }
  }
}
