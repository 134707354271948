import { Component, ElementRef, Input, OnChanges, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import {
  NzCarouselComponent,
  NzCarouselTransformNoLoopStrategy,
  NzCarouselFlipStrategy,
  NZ_CAROUSEL_CUSTOM_STRATEGIES
} from 'ng-zorro-antd/carousel';

@Component({
  selector: 'app-x-form-swiper-video',
  templateUrl: './index.component.html',
  styles: [``],
  providers: [
    {
      provide: NZ_CAROUSEL_CUSTOM_STRATEGIES,
      useValue: [
        { name: 'transform-no-loop', strategy: NzCarouselTransformNoLoopStrategy },
        { name: 'flip', strategy: NzCarouselFlipStrategy }
      ]
    }
  ]
})
export class XFormSwiperVideoComponent implements OnChanges {
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}
  @ViewChild('carousel') carousel: NzCarouselComponent | undefined;

  @Input() items: Array<{ type: string; url: string; alt?: string }> = [];
  @Input() height: any = 10;
  @Input() strategy = 'transform-no-loop';
  @Input() isdesigh: any;
  @ViewChildren('videoElement') videoChildrenRef!: QueryList<ElementRef>;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items'] && this.videoChildrenRef) {
      setTimeout(() => {
        this.videoPlay(0);
      });
    }
  }
  ngAfterViewInit(): void {
    if (this.isdesigh) {
      setTimeout(() => {
        this.videoPlay(0);
      });
    }
  }
  onVideoEnded(index: number): void {
    this.carousel!.next();
  }
  videoPlay(index: number): void {
    const elementRef = this.videoChildrenRef.get(index);
    (elementRef!.nativeElement as HTMLVideoElement).play();
  }
  onCarouselAfterChange(index: number) {
    this.videoPlay(index);
  }
}
