import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-scroll-text',
  templateUrl: './scrolling-subs.component.html',
  styleUrls: ['./scrolling-subs.component.css']
})
export class ScrollingSubsComponent {
  @Input()
  backgroundColor!: string;
  @Input()
  fontSize!: string;
  @Input()
  fontColor!: string;
  @Input()
  scrollSpeed!: string;
  @Input()
  text!: string;
  jsBrige: any = null;
  onAfterView() {
    this.jsBrige = (window as any).jsBrige;
  }
}
