import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgZorroAntdModule } from '../ng-zorro/ng-zorro-antd.module';
import { XFormService } from '../x-form.service';
import { XFormRenderComponent } from './x-form-render.component';
import { XFormComponentModule } from '../x-form-component/x-form-component.module';

@NgModule({
  providers: [XFormService],
  declarations: [XFormRenderComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgZorroAntdModule, XFormComponentModule],
  exports: [XFormRenderComponent, FormsModule, ReactiveFormsModule, NgZorroAntdModule]
})
export class XFormRenderModule {}
