import { NgModule } from '@angular/core';
import { STWidgetRegistry } from '@delon/abc/st';
import { DelonFormModule } from '@delon/form';

import { STdescribeWidget } from './describe/describe.widget';
import { STImgWidget } from './img/img.widget';
import { STTagsWidget } from './tags/tags.widget';
import { SharedModule } from '../shared.module';
import { STCompanyFilessWidget } from './company-files/files.widget';

export const STWIDGET_COMPONENTS = [STTagsWidget, STImgWidget, STdescribeWidget, STCompanyFilessWidget];

@NgModule({
  declarations: STWIDGET_COMPONENTS,
  imports: [SharedModule, DelonFormModule.forRoot()],
  exports: [...STWIDGET_COMPONENTS]
})
export class STWidgetModule {
  constructor(widgetRegistry: STWidgetRegistry) {
    widgetRegistry.register(STTagsWidget.KEY, STTagsWidget);
    widgetRegistry.register(STImgWidget.KEY, STImgWidget);
    widgetRegistry.register(STdescribeWidget.KEY, STdescribeWidget);
    widgetRegistry.register(STCompanyFilessWidget.KEY, STCompanyFilessWidget);
  }
}
