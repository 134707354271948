<div class="d-flex mt-xs" style="height: 100%" [style.background-color]="backgroundColor">
  <div style="position: relative; margin: 0; padding-right: 5px; list-style: none; justify-content: space-evenly"
    class="text-center d-flex text-sm" *ngFor="let item of busStops; let i = index">
    <div>
      <div *ngIf="i !== busStops.length - 1" style="
  position: absolute;
  top: 1px;
  left: 21px;
  height: 9px;
  width: calc(100% - 7px);
  background-color: #4c45be;
  border-radius: 5px;
">
      </div>
      <div style="
  position: absolute;
  width: 12px;
  height: 12px;
  left: 12px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
  border-color: #574fd8;
"></div>
      <div [style.color]="fontColor"
        style="position: relative; top: 13.999px; right: 13px; margin: 0 0 0 26px; word-break: break-word">
        {{ item }}
      </div>

    </div>
  </div>
</div>