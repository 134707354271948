import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgZorroAntdModule } from '../ng-zorro/ng-zorro-antd.module';
import { XFormRenderModule } from '../x-form-render/x-form-render.module';
import { XFormService } from '../x-form.service';
import { XFormDesignComponent } from './x-form-design.component';
import { XFormComponentModule } from '../x-form-component/x-form-component.module';

@NgModule({
  providers: [XFormService],
  declarations: [XFormDesignComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgZorroAntdModule, XFormRenderModule, XFormComponentModule],
  exports: [XFormDesignComponent, FormsModule, ReactiveFormsModule, NgZorroAntdModule]
})
export class XFormDesignModule {}
