<nz-spin nzTip="加载中..." [nzSpinning]="loading">
  <ng-container *ngIf="enableAcl; else disableAcl">
    <button
      *ngFor="let btn of buttons"
      nz-button
      (click)="execute($event, btn.code)"
      [nzType]="btn.type || 'default'"
      [ngClass]="btn.class"
      [disabled]="!btn.enable"
      acl
      [acl-ability]="btn.code"
    >
      {{ btn.title }}
    </button>
    <ng-content select="#rightSide"></ng-content>
  </ng-container>
  <ng-template #disableAcl>
    <button
      *ngFor="let btn of buttons"
      nz-button
      (click)="execute($event, btn.code)"
      [nzType]="btn.type || 'default'"
      [ngClass]="btn.class"
      [disabled]="!btn.enable"
    >
      {{ btn.title }}
    </button>
    <ng-content select="#rightSide"></ng-content>
  </ng-template>
</nz-spin>
