import { Component, OnInit } from '@angular/core';
import { ControlWidget, SFValue } from '@delon/form';

@Component({
  selector: 'sf-time-rang',
  templateUrl: './time-rang.widget.html',
  styles: [
    `
      [nz-button] {
        padding: 0px;
        width: 37px;
      }
    `
  ]
})
export class TimeRangWidget extends ControlWidget {
  static KEY = 'time-rang';

  rangvalue: any = { start: null, end: null };
  override ui!: any;
  change(value: string, type: string): void {
    if (type == 'start') {
      this.rangvalue.start = +value;
    } else if (type == 'end') {
      this.rangvalue.end = +value;
    }
    this.setValue(this.rangvalue);
  }

  override reset(value: SFValue) {
    this.rangvalue = { start: null, end: null };
  }
}
