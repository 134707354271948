<div class="modal-header modal-header-fix">
  <div class="modal-title">{{ options.title }}</div>
</div>
<nz-spin *ngIf="!enableClose" class="modal-spin"></nz-spin>
<ng-template #tpl> </ng-template>
<ng-container *ngIf="options.buttons && options.buttons.length > 0">
  <nz-spin [nzTip]="loadingTip" [nzSpinning]="loading">
    <div class="modal-footer">
      <button
        nz-button
        type="button"
        *ngFor="let button of options.buttons"
        (click)="buttonClick($event, button)"
        [nzType]="button.type || 'default'"
        [nzDanger]="button.isDanger"
      >
        {{ button.title }}
      </button>
    </div>
  </nz-spin>
</ng-container>
