// progress.component.ts
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/app/base.component';

@Component({
  selector: 'app-x-form-progress',
  templateUrl: './index.component.html',
  styles: [
    `
      .timeline-container {
        position: relative;
      }

      .timeline-item {
        display: inline-block;
        position: relative;
        padding-left: 30px;
      }

      /* 左侧虚线 */
      .timeline-line-left {
        position: absolute;
        top: 50%;
        left: calc(100% + 10px);
        width: 2px;
        height: calc(100% - 40px);
        background-color: #ccc;
        transform: translateY(-50%);
        content: '';
      }

      /* 右侧虚线 */
      .timeline-line-right {
        position: absolute;
        top: 50%;
        left: -2px;
        width: 2px;
        height: calc(100% - 40px);
        background-color: #ccc;
        transform: translateY(-50%);
        content: '';
      }
    `
  ]
})
export class XFormProgressComponent extends BaseComponent {
  busStops: any = [
    '阳光小区站',
    '文化艺术中心站',
    '交警队',
    '交通运输局站',
    '锦绣苑小区站',
    '帝景苑小区站',
    '阳光小区站',
    '文化艺术中心站',
    '锦绣苑小区站',
    '帝景苑小区站',
    '阳光小区站',
    '文化艺术中心站',
    '交警队',
    '交通运输局站',
    '锦绣苑小区站',
    '锦绣苑小区站',
    '帝景苑小区站',
    '阳光小区站',
    '文化艺术中心站',
    '交警队',
    '交通运输局站',
    '锦绣苑小区站',
    '帝景苑小区站'
  ];
  @Input()
  backgroundColor!: string;
  @Input()
  fontColor!: string;
}
