<yzb-form
  #read
  [dict]="dict"
  [curd]="8"
  [loading]="loading"
  (initFormEvent)="onInitForm($event)"
  (getFormDataEvent)="onGetFormData($event)"
  (setFormDataEvent)="onSetFormData($event)"
>
</yzb-form>
