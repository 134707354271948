import { EllipsisModule } from '@delon/abc/ellipsis';
import { ExceptionModule } from '@delon/abc/exception';
import { GlobalFooterModule } from '@delon/abc/global-footer';
import { MediaModule } from '@delon/abc/media';
import { PageHeaderModule } from '@delon/abc/page-header';
import { QRModule } from '@delon/abc/qr';
import { ResultModule } from '@delon/abc/result';
import { SEModule } from '@delon/abc/se';
import { SGModule } from '@delon/abc/sg';
import { STModule } from '@delon/abc/st';
import { SVModule } from '@delon/abc/sv';
import { XlsxModule } from '@delon/abc/xlsx';
import { ChartEChartsModule } from '@delon/chart/chart-echarts';
import { AlainThemeModule } from '@delon/theme';

export const SHARED_DELON_MODULES = [
  PageHeaderModule,
  STModule,
  SEModule,
  SVModule,
  ResultModule,
  GlobalFooterModule,
  ResultModule,
  QRModule,
  EllipsisModule,
  MediaModule,
  SGModule,
  ChartEChartsModule,
  XlsxModule,
  ExceptionModule,
  AlainThemeModule.forChild()
];
