import { Router } from '@angular/router';
import { ITokenModel } from '@delon/auth';
import { environment } from '@env/environment';

import { BaseLogic } from './base.logic';
import { MenuLogic } from './menu.logic';
import { IApplicationData } from '../model/core.d';

export class ApplicationLogic extends BaseLogic {
  /**
   * 加载应用信息
   *
   * @author 王阮强(wangruanqiang@youzhibo.cn)
   * @date 2021-02-04
   * @param {ITokenModel} tokenData token数据
   * @param {IApplicationData} [appData=null] 应用数据
   */
  load(tokenData: ITokenModel, appData: IApplicationData | null = null): void {
    // 设置token
    this.tokenSrv.set(tokenData);

    if (null != appData) {
      let { user, functions, menu, appType }: IApplicationData = appData;
      // 保存用户信息
      if (user) this.privilegeSrv.user = user;
      // 保存菜单信息
      if (menu) this.privilegeSrv.menu = menu;
      // 保存功能编码信息
      if (functions) this.privilegeSrv.functions = functions;
      // 保存应用类型
      if (appType) this.privilegeSrv.appType = appType;
    } else {
      this.privilegeSrv.refresh();
    }
    // 加载用户信息
    this.settingsSrv.setUser({
      name: this.privilegeSrv.user?.realname,
      avatar: this.privilegeSrv.user?.img_head,
      ...(this.privilegeSrv.user || {})
    });
    // if (1 == this.privilegeSrv.appType) {
    // }
    // this.menuSrv.add(this.privilegeSrv.menu);
    // 配置权限信息
    this.aclSrv.setAbility(this.privilegeSrv.funcode);
    setTimeout(() => {
      // 加载菜单信息
      this.menuSrv.clear();
      this.menuSrv.add(MenuLogic.getInstance(this.injector).load(this.privilegeSrv.menu!));
    }, 10);
  }

  /**
   * 跳转至登录页面
   *
   * @author 王阮强(wangruanqiang@youzhibo.cn)
   * @date 2021-02-02
   * @private
   * @param {string} [content=null] 提示框详细文本
   * @param {string} [title=null] 提示框标题
   * @param {number} [appType=null] 跳转应用类型
   */
  toLogin(content: string | null = null, title: string | null = null, appType: number | null = null): void {
    this.privilegeSrv.clear();
    // 页面提示
    if (null !== content && null !== title) {
      this.notificationSrv.error(title, content);
    }
    // 根据当前应用类型决定跳转登录页面地址
    appType = appType || 3;
    this.privilegeSrv.appType = appType;
    // 判断企微打开页面时
    // if (/wxwork/i.test(navigator.userAgent)) {
    //   this.tokenSrv.clear();
    //   return;
    // }
    // this.goTo(`/${this.privilegeSrv.appTypeName}${this.tokenSrv.login_url}`);
    // 登录页面判断
    const loginUrl = this.privilegeSrv.loginUrl
      ? this.privilegeSrv.loginUrl
      : `/${this.privilegeSrv.appTypeName}${this.tokenSrv.login_url}`;
    this.tokenSrv.clear();
    // 判断当前页面是否登录页，如果是则无需重复跳转
    // 解析当前页面，因为是单页应用，如果开启了hash则使用#来判断
    if (environment.useHash) {
      const parseUrl = location.href.split('#');
      if (parseUrl.length > 1) {
        // 存在hash地址，比较下是否登录地址
        if (0 == parseUrl[1].indexOf(loginUrl)) {
          return;
        }
      }
    }
    this.goTo(loginUrl);
  }
  toHome() {
    this.goTo(environment.api['homeUrl']);
  }
  /**
   * 跳转页面
   */
  goTo(url: string): void {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }
  /**
   * 应用程序初始化，处理token等信息
   */
  init(appType: number, loginUrl: string | null = null): void {
    // 配置登录地址
    this.privilegeSrv.loginUrl = loginUrl;
    // 验证访问的应用和权限中的应用不一致，则跳转登录
    if (appType != this.privilegeSrv.appType) {
      return this.toLogin(null, null, appType);
    }
    // 判断本地token是否有效
    const tokenData = (this.tokenSrv.get() || {}) as ITokenModel;
    if (Object.keys(tokenData).length > 0) {
      if (!tokenData.expired || tokenData.expired < +new Date()) {
        this.tokenSrv.clear();
        return this.toLogin('登录令牌已过期', '登陆失效');
      }
    } else {
      return this.toLogin(null, null, appType);
    }
    // token有效，则使用token数据重新初始化
    return this.load(tokenData);
  }
}
