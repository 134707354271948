import { Component } from '@angular/core';

@Component({
  selector: 'app-company-home-progress-component',
  template: ` <x-form-render [config]="config"></x-form-render> `
})
export class CompanyHomeProgressComponent {
  config = {
    type: 'form',
    containerWidth: 600,
    children: [
      {
        type: 'img',
        carouselItems: [
          {
            id: 5,
            company_name: '测试企业',
            company: 1,
            group_name: '测试',
            source_material_group: 5,
            code: 'SM120240220002',
            name: '测试2',
            type: 1,
            size: 0,
            suffix_type: '',
            file_url: 'https://dev.st.yfttech.cn/uploads/1/img/2024/02/5fac4ddf7d3b1d880d3232795911da67.png',
            file: 72,
            remark: '',
            state: 0,
            create_real_name_real_name: '测试',
            create_user: 2,
            checked: true
          },
          {
            id: 4,
            company_name: '测试企业',
            company: 1,
            group_name: '测试',
            source_material_group: 5,
            code: 'SM120240220001',
            name: '测试1',
            type: 1,
            size: 0,
            suffix_type: '',
            file_url: 'https://dev.st.yfttech.cn/uploads/1/img/2024/02/2b337ece882be013576d4475f59462cf.png',
            file: 71,
            remark: '',
            state: 0,
            create_real_name_real_name: '测试',
            create_user: 2,
            checked: true
          },
          {
            id: 1,
            company_name: '测试企业',
            company: 1,
            group_name: '123',
            source_material_group: 1,
            code: 'SM120240202006',
            name: '图片',
            type: 1,
            size: 0,
            suffix_type: '',
            file_url: 'https://dev.st.yfttech.cn/uploads/1/img/2024/02/cc7fab9428da93ed664c34fdec1fedd9.png',
            file: 50,
            remark: '',
            state: 0,
            create_real_name_real_name: '测试',
            create_user: 2,
            checked: true
          }
        ]
      },
      {
        type: 'scroll-text',
        backgroundColor: '#ff57b0',
        fontColor: '#6a6d9a',
        fontSize: 20,
        height: 66,
        scrollSpeed: 10,
        text: '中2国特色社会主义：理论是思想的旗帜，旗帜是前行的方向。旗帜指引方向、凝聚力量.方位决定方略，方略明确使命，使命昭示未来。'
      },
      {
        type: 'video',
        carouselItems: [
          {
            index: 0,
            data: {
              id: 3,
              company_name: '测试企业',
              company: 1,
              group_name: '测试',
              source_material_group: 5,
              code: 'SM120240207001',
              name: '1111',
              type: 2,
              size: 0,
              suffix_type: '',
              file_url: 'https://dev.st.yfttech.cn/uploads/1/img/2024/02/1f4b5c1924a261b97906e580466ac149.mp4',
              file: 53,
              remark: '',
              state: 0,
              create_real_name_real_name: '测试',
              create_user: 2,
              _values: [
                {
                  text: '',
                  _text: {
                    changingThisBreaksApplicationSecurity: ''
                  },
                  safeType: 'safeHtml',
                  buttons: [],
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: 1,
                  _text: {
                    changingThisBreaksApplicationSecurity: 1
                  },
                  safeType: 'safeHtml',
                  buttons: [],
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: '测试',
                  _text: {
                    changingThisBreaksApplicationSecurity: '测试'
                  },
                  org: '测试',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: 'SM120240207001',
                  _text: {
                    changingThisBreaksApplicationSecurity: 'SM120240207001'
                  },
                  org: 'SM120240207001',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: '1111',
                  _text: {
                    changingThisBreaksApplicationSecurity: '1111'
                  },
                  org: '1111',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: '0',
                  _text: {
                    changingThisBreaksApplicationSecurity: '0'
                  },
                  org: '0',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: '-',
                  _text: {
                    changingThisBreaksApplicationSecurity: '-'
                  },
                  org: '-',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: 53,
                  _text: {
                    changingThisBreaksApplicationSecurity: 53
                  },
                  org: 53,
                  safeType: 'safeHtml',
                  buttons: [],
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: '未审核',
                  _text: {
                    changingThisBreaksApplicationSecurity: '未审核'
                  },
                  org: '未审核',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                }
              ],
              _rowClassName: 'bg-white',
              checked: true
            },
            type: 'video',
            url: 'https://dev.st.yfttech.cn/uploads/1/img/2024/02/1f4b5c1924a261b97906e580466ac149.mp4'
          },
          {
            index: 1,
            data: {
              id: 2,
              company_name: '测试企业',
              company: 1,
              group_name: '123',
              source_material_group: 1,
              code: 'SM120240202007',
              name: '视频11',
              type: 2,
              size: 0,
              suffix_type: '',
              file_url: 'https://dev.st.yfttech.cn/uploads/1/img/2024/02/7b922fb908ae3c46f85f02297811fff0.mp4',
              file: 51,
              remark: '',
              state: 0,
              create_real_name_real_name: '测试',
              create_user: 2,
              _values: [
                {
                  text: '',
                  _text: {
                    changingThisBreaksApplicationSecurity: ''
                  },
                  safeType: 'safeHtml',
                  buttons: [],
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: 2,
                  _text: {
                    changingThisBreaksApplicationSecurity: 2
                  },
                  safeType: 'safeHtml',
                  buttons: [],
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: '123',
                  _text: {
                    changingThisBreaksApplicationSecurity: '123'
                  },
                  org: '123',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: 'SM120240202007',
                  _text: {
                    changingThisBreaksApplicationSecurity: 'SM120240202007'
                  },
                  org: 'SM120240202007',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: '视频11',
                  _text: {
                    changingThisBreaksApplicationSecurity: '视频11'
                  },
                  org: '视频11',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: '0',
                  _text: {
                    changingThisBreaksApplicationSecurity: '0'
                  },
                  org: '0',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: '-',
                  _text: {
                    changingThisBreaksApplicationSecurity: '-'
                  },
                  org: '-',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: 51,
                  _text: {
                    changingThisBreaksApplicationSecurity: 51
                  },
                  org: 51,
                  safeType: 'safeHtml',
                  buttons: [],
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                },
                {
                  text: '未审核',
                  _text: {
                    changingThisBreaksApplicationSecurity: '未审核'
                  },
                  org: '未审核',
                  safeType: 'safeHtml',
                  props: {
                    colSpan: 1,
                    rowSpan: 1
                  }
                }
              ],
              _rowClassName: 'bg-white',
              checked: true
            },
            type: 'video',
            url: 'https://dev.st.yfttech.cn/uploads/1/img/2024/02/7b922fb908ae3c46f85f02297811fff0.mp4'
          }
        ],
        height: 254
      },
      {
        type: 'scroll-text',
        backgroundColor: '#90979d',
        fontColor: '#353db6',
        fontSize: 14,
        height: 51,
        scrollSpeed: 50,
        text: '1中国特色社会主义：理论是思想的旗帜，旗帜是前行的方向。旗帜指引方向、凝聚力量.方位决定方略，方略明确使命，使命昭示未来。'
      }
    ]
  };
}
