import { Subject } from 'rxjs';

import { EVENT_LOADING } from './../consts/component.consts';
import { IStringPaire } from './../model/core.d';
import { BaseLogic } from './base.logic';
import { IJsonTable } from '../model/core.d';
import { IRspSubjectSet } from '../model/http';

export class HttpLogic extends BaseLogic {
  /**
   * http请求方法，统一处理loading和响应信息，返回成功和失败订阅对象
   *
   * @author 王阮强(wangruanqiang@youzhibo.cn)
   * @date 2021-01-27
   * @public
   * @param {string} method 请求方法
   * @param {string} url 请求地质
   * @param {object} options 配置信息
   * @returns {*}  {IRspSubjectSet}
   */
  public request(method: string, url: string, options: IStringPaire): IRspSubjectSet {
    // 成功和失败的订阅
    const success = new Subject<IJsonTable>(),
      error = new Subject<IJsonTable>(),
      complete = new Subject<void>();
    // 发布加载中消息
    this.evtSrv.publish(EVENT_LOADING, true);
    this.http.request<IJsonTable>(method, url, options).subscribe({
      next: (result: IJsonTable) => {
        if (0 == result.state) {
          if (success.observed) {
            success.next(result);
          } else {
            this.msgSrv.success('success' == result.msg ? '执行成功' : result.msg);
          }
        } else {
          if (error.observed) {
            error.next(result);
          } else {
            // 未配置错误回调，则统一处理
            if (1 == result.state) {
              this.msgSrv.error('系统繁忙，请稍后再试!');
            } else {
              this.msgSrv.error(`${result.msg}[${result.state}]`);
            }
          }
        }
      },
      complete: () => {
        if (complete.observed) {
          complete.next();
        }
        // 发布加载结束消息
        this.evtSrv.publish(EVENT_LOADING, false);
      }
    });
    // 返回subject对象方便上层订阅
    return {
      success: success.asObservable(),
      error: error.asObservable(),
      complete: complete.asObservable()
    };
  }

  public reqGet(url: string, params?: any, options?: IStringPaire): IRspSubjectSet {
    options = { params, ...options };
    return this.request('get', url, options);
  }

  public reqPost(url: string, body?: any, params?: any, options?: any): IRspSubjectSet {
    options = { params, body, ...options };
    return this.request('post', url, options);
  }

  public reqPut(url: string, body?: any, params?: any, options?: any): IRspSubjectSet {
    options = { params, body, ...options };
    return this.request('put', url, options);
  }

  public reqDelete(url: string, params?: any, options?: IStringPaire): IRspSubjectSet {
    options = { params, ...options };
    return this.request('delete', url, options);
  }
}
