import { Component } from '@angular/core';
import { BaseUpdateComponent } from 'src/app/shared/component/app/curd/base-update.component';
import { CompanyMaterialGroupIndexComponent } from './index.component';
import { CompanyUpMaterialGroupIndexComponent } from './up-group.component';
@Component({
  selector: 'app-company-MaterialGroup-update',
  templateUrl: './../../../../shared/component/app/curd/base-update.component.html'
})
export class CompanyMaterialGroupUpdateComponent extends BaseUpdateComponent {
  override ngOnInit() {
    this.baseUri = 'company/v1/source_material_group';
    this.dictId = 1011;
    this.dictComponent = {
      1011: CompanyUpMaterialGroupIndexComponent
    };
    super.ngOnInit();
  }
}
