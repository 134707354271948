import { Menu } from '@delon/theme';

import { BaseLogic } from './base.logic';
import { IMenuData, IMenuSet } from '../model/http.d';

export class MenuLogic extends BaseLogic {
  load(menuSet: IMenuSet): Menu[] {
    return [
      {
        text: '',
        group: true,
        hideInBreadcrumb: true,
        children: this.parseMenu(menuSet)
      }
    ];
  }
  /**
   * 将接口返回的菜单数据转换为框架所需要的结构
   *
   * @author 王阮强(wangruanqiang@youzhibo.cn)
   * @date 2021-02-22
   * @param {IMenuSet} menuSet 接口返回的菜单集合
   * @returns {*}  {Menu[]}
   */
  parseMenu(menuSet: IMenuSet): Menu[] {
    const menu: Menu[] = [];
    Object.keys(menuSet).forEach(key => {
      const data: IMenuData = menuSet[key],
        item: Menu = {
          text: data.title,
          icon: data.icon,
          link: data.uri,
          acl: {
            ability: [`${data.code!.replace('MN', 'FN')}00`]
          },
          key,
          open: false
        };
      // 菜单关闭或显示样式不是左侧菜单，则跳过
      if (0 == data.state || 1 != data.style) return;
      if (data.parented && data.children && Object.keys(data.children).length > 0) {
        // 有子级菜单，则递归处理
        item.children = this.parseMenu(data.children);
      }
      menu.push(item);
    });
    return menu;
  }
}
