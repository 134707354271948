import { Injector } from '@angular/core';
import { CommonService, PrivilegeService } from '@core';
import { ACLService } from '@delon/acl';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { _HttpClient, MenuService, SettingsService } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { EventService } from './../../core/service/event.service';

/**
 * 逻辑基类
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-01-29
 * @class BaseLogic
 */
export class BaseLogic {
  //#region 通用服务
  protected tokenSrv: ITokenService;
  protected privilegeSrv: PrivilegeService;
  protected settingsSrv: SettingsService;
  protected cacheSrv: CacheService;
  protected aclSrv: ACLService;
  protected http: _HttpClient;
  protected menuSrv: MenuService;
  protected commonSrv: CommonService;
  protected notificationSrv: NzNotificationService;
  protected msgSrv: NzMessageService;
  protected evtSrv: EventService;
  //#endregion
  constructor(protected injector: Injector) {
    this.tokenSrv = this.injector.get(DA_SERVICE_TOKEN);
    this.privilegeSrv = this.injector.get(PrivilegeService);
    this.settingsSrv = this.injector.get(SettingsService);
    this.cacheSrv = this.injector.get(CacheService);
    this.aclSrv = this.injector.get(ACLService);
    this.http = this.injector.get(_HttpClient);
    this.menuSrv = this.injector.get(MenuService);
    this.commonSrv = this.injector.get(CommonService);
    this.notificationSrv = this.injector.get(NzNotificationService);
    this.msgSrv = this.injector.get(NzMessageService);
    this.evtSrv = this.injector.get(EventService);
    this.initialize();
  }

  protected initialize() {}

  static getInstance<T extends {}>(this: new (injector: Injector) => T, injector: Injector): T {
    if (!(<any>this)._instance) {
      (<any>this)._instance = new this(injector);
    }
    return (<any>this)._instance;
  }

  protected setCache(key: string, value: any, expire: number = 7200, type: 's' | 'm' = 's') {
    this.cacheSrv.set(key, value, {
      type: type,
      expire: expire
    });
  }

  protected getCache(key: string): any {
    this.cacheSrv.get(key, {
      mode: 'none'
    });
  }
}
