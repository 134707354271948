import { Component } from '@angular/core';
import { BaseUpdateComponent } from 'src/app/shared/component/app/curd/base-update.component';
import { CompanyMaterialGroupIndexComponent } from '../material-group/index.component';
@Component({
  selector: 'app-company-Material-update',
  templateUrl: './../../../../shared/component/app/curd/base-update.component.html'
})
export class CompanyMaterialUpdateComponent extends BaseUpdateComponent {
  override ngOnInit() {
    this.baseUri = 'company/v1/source_material';
    this.dictId = 1012;
    this.dictComponent = {
      1011: CompanyMaterialGroupIndexComponent
    };
    super.ngOnInit();
  }
}
