<ng-container *ngIf="items.length > 0">
  <div [style.height.px]="height">
    <nz-carousel [nzEffect]="strategy" #carousel (nzAfterChange)="onCarouselAfterChange($event)">
      <div nz-carousel-content [style.height.px]="height" *ngFor="let item of items; let i = index">
        <video #videoElement width="100%" height="100%" [attr.data-index]="i" [src]="item.url" (ended)="onVideoEnded(i)">
          Your browser does not support the video tag.
        </video>
      </div>
    </nz-carousel>
  </div>
</ng-container>
<ng-container *ngIf="items.length == 0">
  <div style="height: 100%; background-color: antiquewhite" class="text-center text-md"> 视频区域 </div>
</ng-container>
