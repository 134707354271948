import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'st-widget-patient_describe',
  template: `
    <span
      *ngIf="describe; else elseBlock"
      nz-tooltip
      nzTooltipTitle="当前患者存在未开始或正在进行的随访计划"
      style="cursor: pointer;color:#D96A09"
    >
      {{ name }}
    </span>

    <ng-template #elseBlock>
      <span>{{ name }}</span>
    </ng-template>
  `,

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class STdescribeWidget {
  static readonly KEY = 'patient_describe';
  describe!: any;
  name!: string;
}
