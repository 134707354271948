import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { NzCarouselTransformNoLoopStrategy, NzCarouselFlipStrategy, NZ_CAROUSEL_CUSTOM_STRATEGIES } from 'ng-zorro-antd/carousel';

@Component({
  selector: 'app-x-form-swiper-img',
  templateUrl: './index.component.html',
  styles: [``],
  providers: [
    {
      provide: NZ_CAROUSEL_CUSTOM_STRATEGIES,
      useValue: [
        { name: 'transform-no-loop', strategy: NzCarouselTransformNoLoopStrategy },
        { name: 'flip', strategy: NzCarouselFlipStrategy }
      ]
    }
  ]
})
export class XFormSwiperImgComponent {
  effect = 'scrollx';
  @Input() items: Array<{ type?: string; url: string; alt?: string }> = [];
  @Input() xFormAutoPlaySpeed: number = 3;
  @Input() strategy = 'transform-no-loop';
}
