import { Component } from '@angular/core';
import { BaseReadComponent } from 'src/app/shared/component/app/curd/base-read.component';

@Component({
  selector: 'app-company-Material-read',
  templateUrl: './../../../../shared/component/app/curd/base-read.component.html'
})
export class CompanyMaterialReadComponent extends BaseReadComponent {
  override ngOnInit() {
    this.baseUri = 'company/v1/source_material';
    this.dictId = 1012;

    super.ngOnInit();
  }
}
