import { NgModule } from '@angular/core';
import { ScrollingSubsComponent } from './scroll/scrolling-subs.component';
import { XFormSwiperImgComponent } from './swiper-img/index.component';
import { SHARED_ZORRO_MODULES } from 'src/app/shared/shared-zorro.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { XFormSwiperVideoComponent } from './swiper-video/index.component';
import { XFormDateWeatherComponent } from './date-weather/index.component';
import { XFormProgressComponent } from './progress/index.component';

@NgModule({
  declarations: [
    ScrollingSubsComponent,
    XFormSwiperImgComponent,
    XFormSwiperVideoComponent,
    XFormDateWeatherComponent,
    XFormProgressComponent
  ],
  imports: [...SHARED_ZORRO_MODULES, CommonModule, FormsModule],
  exports: [ScrollingSubsComponent, XFormSwiperImgComponent, XFormSwiperVideoComponent, XFormDateWeatherComponent, XFormProgressComponent]
})
export class XFormComponentModule {}
