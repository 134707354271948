import { Component } from '@angular/core';
import { BaseSaveComponent } from 'src/app/shared/component/app/curd/base-save.component';
import { CompanyMaterialGroupIndexComponent } from '../material-group/index.component';

// import { CompanyMechanismIndexComponent } from '../mechanism/index.component';

@Component({
  selector: 'app-company-Material-save',
  templateUrl: './../../../../shared/component/app/curd/base-save.component.html'
})
export class CompanyMaterialSaveComponent extends BaseSaveComponent {
  override ngOnInit() {
    this.baseUri = 'company/v1/source_material';
    this.dictComponent = {
      1011: CompanyMaterialGroupIndexComponent
    };
    this.dictId = 1012;
    super.ngOnInit();
  }
}
