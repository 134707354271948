import { formatDate } from '@angular/common';
import { ComponentRef, Injector, Type, ViewContainerRef } from '@angular/core';
import { STColumnYn } from '@delon/abc/st';
import { SFSchemaEnum } from '@delon/form';
import { getTimeDistance, toNumber } from '@delon/util';
import { toByteArray } from 'base64-js';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

import { IYzbButton } from './../component/common/yzb-button-group.component';
import { SEX } from './../consts/user.consts';
import { IArrayPaire, IConditionSet } from './../model/core.d';
import * as validation from './test';
import { IJsonTable } from '../model/core.d';

// 共享函数
// 需要注意部分变量不同项目内单独维护
export function formatMsTime(msecond: number, format: string = 'yyyy-MM-dd HH:mm:ss', locale: string = 'zh'): string {
  return formatDate(msecond, format, locale);
}

export function formatSecTime(second: number, format: string = 'yyyy-MM-dd HH:mm:ss', locale: string = 'zh'): string {
  second = Number(second);
  let time = '';
  second ? (time = formatMsTime(second * 1000, format, locale)) : (time = '-');
  return time;
}
/**
 * 根据小时数获取今日时间
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-04-13
 * @export
 * @param {number} [hour=0] 小时
 * @returns {*}  {number} 返回当天时间毫秒值
 */
export function dayTimeByHour(hour: number = 0): number {
  hour = hour < 0 ? 0 : hour > 23 ? 23 : hour;
  return getTimeDistance('today')[0].setHours(hour);
}

/**
 * 生成指定范围的随机数
 *
 * @param min 最小值
 * @param max 最大值
 * @returns 返回生成的随机数
 */
export function random(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min)) + min;
}
/**
 * 从select内容获取yes、no的展示数据
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2020-12-28
 * @export
 * @param {string} select 字典配置的select数据
 * @returns {*}  {{ yes: string; no: string }}
 */
export function ynFromSelect(select: string, mode: 'text' | 'full' | 'icon' = 'text', truth: any = 1): STColumnYn {
  let result = {
    yes: '是',
    no: '否'
  };
  if (select) {
    select.split(';').forEach(item => {
      let arr = item.split('-');
      if ('1' == arr[0]) {
        result['yes'] = arr[1];
      } else {
        result['no'] = arr[1];
      }
    });
  }
  return { ...result, truth: truth, mode: mode };
}
/**
 * select转换为enum对象数组
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-01-22
 * @export
 * @param {string} select 字典项select字符串
 * @param {string} [sp='-'] 分隔符
 * @param {boolean} [numbered=true] value是否是数值
 * @returns {*}  {SFSchemaEnum[]}
 */
export function enumFromSelect(select: string, sp: string = '-', numbered: boolean = true): SFSchemaEnum[] {
  let result: SFSchemaEnum[] = [
    {
      label: '请选择',
      value: null
    }
  ];
  select.split(';').forEach(item => {
    const arr = item.split(sp);
    result.push({
      label: arr[1],
      value: numbered ? toNumber(arr[0]) : arr[0] // value必须为数值型
    });
  });
  return result;
}
/**
 * 从select字符串中获取指定值的内容
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-01-22
 * @export
 * @param {*} value 需要比较的值
 * @param {string} select 字段select字符串
 * @param {string} [sp='-'] 分隔符
 * @returns {*} string 返回获取到的内容
 */
export function showFromSelect(value: any, select: string): string {
  let result: string = '';
  // 分割每个选项
  select.split(';').every(item => {
    const arr = item.split('-');
    // 判断比较的value是否和分隔符前半部分一致
    if (arr.length > 0 && value == arr[0]) {
      // 配置返回值
      result = arr.length > 1 ? arr[1] : value;
      // 终止循环
      return false;
    }
    return true;
  });
  return result;
}

/**
 * 转换性别为动态表单枚举对象
 */
export function sex2SFSchemaEnum(): SFSchemaEnum {
  return Object.keys(SEX).map(item => {
    return { lable: Number.parseInt(SEX[item]), value: item };
  });
}
/**
 * 获取所需类型的按钮
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2020-12-30
 * @export
 * @param {IYzbButton[]} buttons
 * @param {number} [style=1]
 * @returns {*}  {IYzbButton[]}
 */
export function filterButtons(buttons: IYzbButton[], style: number = 1): IYzbButton[] {
  let result: IYzbButton[] = [],
    buttonDefault: IYzbButton = {
      title: '',
      code: '',
      type: 'default',
      style: 1,
      class: '',
      enable: true
    };
  buttons.forEach(button => {
    // 检查按钮类型是否是顶部按钮
    button = Object.assign(buttonDefault, button);
    if (button.style == (button.style || 0 & style)) {
      result.push(button);
    }
  });
  return result;
}

export function arr2tree(): any[] {
  //  {name:'',children:[{name:'',children:[{name:''}]}]}
  const src: any = {},
    top: any[] = [];
  const data = ['a/b/c', 'a/b/d', 'a/e/f', 'x/y/z'];
  data.forEach(item => {
    let arrPath: any[] = [];
    item.split('/').forEach(($item, $idx) => {
      const parentPath = arrPath.join('/');
      let parentObj: any = null;
      // 寻找上级节点
      if ('' != parentPath && src[parentPath]) {
        parentObj = src[parentPath];
      }
      // 路径添加本次节点
      arrPath.push($item);
      const currPath = arrPath.join('/');
      let currObj: NzSafeAny = null;
      // 处理本次节点对象，若无则创建
      if (!src[currPath]) {
        currObj = { name: $item, path: currPath };
        src[currPath] = currObj;
      }
      currObj = src[currPath];
      // 存在上级节点，则将本节点添加至上级节点的children内
      if (null != parentObj) {
        parentObj.children = parentObj.children || [];
        if (!parentObj.children.find((currValue: NzSafeAny) => currValue == currObj)) {
          parentObj.children.push(currObj);
        }
      }
      // 判断当前节点是否是顶级节点，且数组内未存在当前节点
      if (0 == $idx && !top.find(currValue => currValue == currObj)) {
        top.push(currObj);
      }
    });
  });
  return top;
}

/**
 * 通过身份证号 获取年龄 生日 性别
 *
 * @author 苏小风(sujiachen@youzhibo.cn)
 * @date 2021-01-18
 * @export
 * @param {idCard} string // 身份证号
 * @returns { data: { age: number, birthday: string, sex: string|null } }
 */
export function idCardToAgeSexBirthday(idCard: string) {
  let data: {
    age: number;
    birthday: string;
    sex: string | null;
    age_unit: string;
  } = {
    age: 0,
    birthday: '',
    sex: null,
    age_unit: '1' // 1:岁, 2:月, 3:天
  };
  // 判断身份证号正确 有对应方法
  if (validation.idCard(idCard)) {
    let sex = idCard.substring(16, 17);
    data.sex = Number(sex) % 2 == 0 ? '2' : '1'; // 性别，身份证第17位 奇数为男 偶数为女
    let b = idCard.substring(6, 14); // '19900102'
    let yy = b.substring(0, 4); // 年 '1990'
    let MM = b.substring(4, 6); // 月 '01'
    let dd = b.substring(6, 8); // 日 '02'
    let yyMMdd = `${yy}-${MM}-${dd}`; // 获取生日 '1990-01-02'
    data.birthday = yyMMdd;
    // 计算年龄
    let fullyear = new Date().getFullYear(); // 2021
    let month = new Date().getMonth() + 1; // 1
    let date = new Date().getDate(); // 15
    let age = fullyear - Number(yy);
    if (age > 0) {
      if (Number(MM) < month) {
        data.age = age - 1;
      } else if (Number(MM) == month) {
        if (Number(dd) >= date) {
          data.age = age;
        } else {
          data.age = age - 1;
        }
      } else {
        data.age = age;
      }
    } else if (age == 0) {
      // 小于一岁时
      // 生日月份<当月时 年纪为 当月-生日月 单位‘月’
      // 生日月份=当月时 判断日期; 年纪为 当天日期-生日日期 单位‘天’
      if (Number(MM) < month) {
        data.age = month - Number(MM);
        data.age_unit = '2';
        if (Number(dd) < date) {
          // 天数不足一个月时
          data.age = data.age - 1;
        }
      } else if (Number(MM) == month) {
        // 小于一个月
        if (Number(dd) <= date) {
          data.age = date - Number(dd);
          data.age_unit = '3';
        }
      }
    }
  }
  return data;
}
/**
 * @param {dDate} Date // 时间格式
 * @returns { data: { age: number, age_unit: string } } // 年龄, 年龄单位 1:岁, 2:月, 3:天
 */
// 根据生日计算年龄
export function birthdayToAge(dDate: Date) {
  if (!dDate) return;
  let data: {
    age: number;
    age_unit: string;
  } = {
    age: 0,
    age_unit: '1' // 1:岁, 2:月, 3:天
  };
  const now = new Date();
  const diff = now.getTime() - dDate.getTime();
  const ageDate = new Date(diff);
  const year = ageDate.getUTCFullYear() - 1970;
  const month = ageDate.getUTCMonth();
  const day = ageDate.getUTCDate() - 1;
  if (year === 0) {
    if (month === 0) {
      data.age = day;
      data.age_unit = '3';
    } else {
      data.age = month;
      data.age_unit = '2';
    }
  } else {
    data.age = year;
    data.age_unit = '1';
  }
  return data;
}
/**
 * 时间戳转日期 去掉时分秒
 *
 * @author 苏小风(sujiachen@youzhibo.cn)
 * @date 2021-02-23
 * @export
 * @param {dDate} Date // 时间格式
 * @returns {*} 时间戳 秒
 */
export function dateTimeToUnixDate(dDate: Date): number {
  let unixdate;
  const dateText = `${dDate.getFullYear()}-${dDate.getMonth() + 1}-${dDate.getDate()}`;
  unixdate = new Date(dateText).getTime() / 1000;
  return unixdate;
}
/**
 * 日期区间转换为秒时间戳
 *
 * @author 王阮强(wangruanqiang@hongshanhis.com)
 * @date 2022-11-30
 * @export
 * @param {(Date | number)} start 起始时间
 * @param {(Date | number)} end 结束时间
 * @returns {*}  {[number, number]} 返回时间范围，第一个是起始时间当天0点，第二个是结束时间第二天0点
 */
export function dayRangeToUnixDate(start: Date | number, end: Date | number): [number, number] {
  const startDate: Date = Number.isInteger(start) ? new Date(start) : (start as Date);
  const endDate: Date = Number.isInteger(end) ? new Date((end as number) + 86400000) : new Date((end as Date).getTime() + 86400000);
  return [dateTimeToUnixDate(startDate), dateTimeToUnixDate(endDate)];
}
/**
 * 获取当天0点
 *
 * @param mstime 毫秒时间戳
 * @param locale 时区
 * @returns 返回秒级时间戳
 */
export function zeroOfDayByMsTime(mstime: number, locale: string = 'zh'): number {
  const date = new Date(formatMsTime(mstime, 'yyyy-MM-dd 00:00:00', locale));
  return date.getTime() / 1000;
}

/**
 * 字符串转VS数据
 *
 * @author 王阮强(wangruanqiang@hongshanhis.com)
 * @date 2022-09-13
 * @export
 * @param {string} str kv结构字符串
 * @param {string} [sp='-'] 分隔符
 * @returns {*}  {({ value: string | number; show: string | null})}
 */
export function str2VS(str: string, sp: string = '-'): { value: string | number; show: string | null } {
  const arr = String(str).split(sp);
  return {
    value: arr[0] || '',
    show: arr[1] || null
  };
}
/**
 * vs结构数据转换为字符串
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-01-21
 * @export
 * @param {(string | number)} value vs的值
 * @param {string} show vs的显示内容
 * @param {string} [sp='-'] 分隔符
 * @returns {*}  {string} 返回组装好的字符串
 */
export function vs2Str(value: string | number, show: string, sp: string = '-'): string {
  return `${value}${sp}${show}`;
}

/**
 * 判断给定字符串是否vs结构数据
 *
 * @author 王阮强(wangruanqiang@hongshanhis.com)
 * @date 2021-06-18
 * @export
 * @param {string} str 待判断的字符串
 * @param {string} [sp='-'] 分隔符
 * @returns {*}  {boolean} 返回判定结果，true是vs字符串，false不是
 */
export function isVSStr(str: string, sp: string = '-'): boolean {
  return str.split(sp).length > 1;
}

/**
 * 动态创建组件
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-01-27
 * @export
 * @param {Type<any>} componentType 组件类类型
 * @param {Injector} injector 依赖注入对象
 * @returns {*}  {ComponentRef<any>} 返回组件引用
 */
export function createComponent(componentType: Type<any>, injector: Injector): ComponentRef<any> {
  const vcr = injector.get(ViewContainerRef),
    componentRef = vcr.createComponent(componentType, { injector }),
    view = componentRef.hostView;
  vcr.insert(view);
  return componentRef;
}
/**
 * 创建IJsonTable数据格式
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-01-27
 * @export
 * @param {number} state 状态码
 * @param {(string | any)} msg 消息
 * @param {*} [data] 详细数据
 * @returns {*}  {IJsonTable}
 */
export function jtable(state: number, msg: string | any, data?: any): IJsonTable {
  const result: IJsonTable = {
    state: state,
    msg: msg
  };
  if (data) {
    result.data = data;
  }
  return result;
}
/**
 * 获取表示错误信息的IJsonTable数据格式
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-01-27
 * @export
 * @param {(string | any)} [msg='error'] 错误信息
 * @param {number} [state=1] 状态码
 * @param {*} [data] 附加信息
 * @returns {*}  {IJsonTable}
 */
export function jerror(msg: string | any = 'error', state: number = 1, data?: any): IJsonTable {
  return jtable(state, msg, data);
}
/**
 * 获取表示正确信息的IJsonTable数据
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-01-27
 * @export
 * @param {(string | any)} [msg='success'] 消息
 * @param {*} [data] 附加信息
 * @returns {*}  {IJsonTable}
 */
export function jsuccess(msg: string | any = 'success', data?: any): IJsonTable {
  return jtable(0, msg, data);
}
/**
 * 命名风格转换
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-02-03
 * @export
 * @param {string} name 待转换的名称
 * @param {number} [type=0] 0：驼峰转下划线；1：下划线转驼峰
 * @param {boolean} [ucfirst=true] 驼峰模式下首字母是否大写
 * @returns {*}  {string}
 */

export function parseName(name: string, type: number = 0, ucfirst: boolean = true): string {
  if (1 == type) {
    const result = name.replace(/_([a-zA-Z])/g, str => {
      return str.toUpperCase();
    });
    return ucfirst
      ? result.substring(0, 1).toUpperCase() + result.substring(1)
      : result.substring(0, 1).toLowerCase() + result.substring(1);
  }
  const result = name.replace(/[A-Z]/g, str => {
    return `_${str.toLowerCase()}`;
  });
  return '_' == result.substring(0, 1) ? result.substring(1) : result;
}
// https://angular.io/guide/styleguide#style-04-12
export function throwIfAlreadyLoaded(parentModule: any, moduleName: string): void {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}

export function parseConditionSet(condition: IConditionSet): IArrayPaire {
  const result: IArrayPaire = {};
  Object.keys(condition).forEach(key => {
    const item = condition[key];
    // 若为空字符串，或值不存在则跳过
    if ('' === item.value || null == item.value) return;
    result[key] = [item.field, item.operator, item.value];
  });
  return result;
}
/**
 * 删除对象中指定的属性
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-04-26
 * @export
 * @param {*} obj 待处理的对象
 * @param {(string | string[])} attributes 属性集合
 */
export function delObjAttr(obj: Object, attributes: string | string[]): Object {
  if (!Array.isArray(attributes)) {
    attributes = [attributes];
  }
  (attributes as string[]).forEach(item => {
    delete (obj as { [key: string]: any })[item];
  });
  return obj;
}
// js加减乘除丢失精度 处理
// 加法
export function accAdd(arg1: NzSafeAny, arg2: NzSafeAny) {
  var r1, r2, m, c;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''));
      arg2 = Number(arg2.toString().replace('.', '')) * cm;
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm;
      arg2 = Number(arg2.toString().replace('.', ''));
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''));
    arg2 = Number(arg2.toString().replace('.', ''));
  }
  return (arg1 + arg2) / m;
}
// 减法
export function accSub(arg1: NzSafeAny, arg2: NzSafeAny) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}
/**
 ** 乘法函数，用来得到精确的乘法结果
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 ** 调用：accMul(arg1,arg2)
 ** 返回值：arg1乘以 arg2的精确结果
 **/
export function accMul(arg1: NzSafeAny, arg2: NzSafeAny) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split('.')[1].length;
  } catch (e) {}
  try {
    m += s2.split('.')[1].length;
  } catch (e) {}
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
}
/**
 ** 除法函数，用来得到精确的除法结果
 ** 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
 ** 调用：accDiv(arg1,arg2)
 ** 返回值：arg1除以arg2的精确结果
 **/
export function accDiv(arg1: NzSafeAny, arg2: NzSafeAny) {
  arg1 = Number(arg1);
  arg2 = Number(arg2);
  if (!arg2) {
    return null;
  }
  if (!arg1 && arg1 !== 0) {
    return null;
  } else if (arg1 === 0) {
    return 0;
  }
  var n1, n2;
  var r1, r2; // 小数位数
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  n1 = Number(arg1.toString().replace('.', ''));
  n2 = Number(arg2.toString().replace('.', ''));
  return accMul(n1 / n2, Math.pow(10, r2 - r1));
}
// 分转元 逗号隔开
export function abs(val: NzSafeAny, type = 1) {
  //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
  var str = `${(val / 100).toFixed(2)}`;
  // type!=1 时 无需逗号分开
  if (type != 1) return Number(str);
  var intSum = str.substring(0, str.indexOf('.')).replace(/\B(?=(?:\d{3})+$)/g, ','); //取到整数部分
  var dot = str.substring(str.length, str.indexOf('.')); //取到小数部分搜索
  var ret = intSum + dot;
  return ret;
}

/**
 * 导出流数据保存为文件
 *
 * @param fileName 文件名
 * @param content 文件流数据
 */
export function downloadFile(fileName: string, content: NzSafeAny) {
  const blob = new Blob([content]);
  if ((window.navigator as NzSafeAny).msSaveOrOpenBlob) {
    (navigator as NzSafeAny).msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.style.display = 'none';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    //此写法兼容可火狐浏览器
    // 未验证，后面如果遇到问题，可以考虑在这里做浏览器判断
    // const evt = new Event('download', { bubbles: false, cancelable: false });
    // link.dispatchEvent(evt);
    document.body.removeChild(link);
  }
}
// base64文件转换Blob 用于导出
export function base64ToBlob(code: string) {
  const parts = code.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = toByteArray(parts[1]);
  const uInt8Array = raw;
  return new Blob([uInt8Array], { type: contentType });
}
/**
 * 过滤对象中的无效值
 *
 * @author 王阮强(wangruanqiang@youzhibo.cn)
 * @date 2021-05-14
 * @export
 * @param {any} obj
 * @returns {any}
 */
export function filterNullProperties(obj: any): any {
  const result: { [key: string]: string } = {};
  Object.keys(obj).forEach(key => {
    if (null !== obj[key] && undefined !== obj[key]) {
      result[key] = obj[key];
    }
  });
  return result;
}
// 获取浏览器信息
export function browserInfo() {
  var res = {
    name: '',
    version: ''
  };
  var reg;
  var userAgent = self.navigator.userAgent;
  if ((reg = /edge\/([\d\.]+)/i.exec(userAgent))) {
    res.name = 'Edge';
    res.version = reg[1];
  } else if (/msie/i.test(userAgent)) {
    res.name = 'Internet Explorer';
    reg = /msie ([\d\.]+)/i.exec(userAgent);
    res.version = reg ? reg[1] : '';
  } else if (/Trident/i.test(userAgent)) {
    res.name = 'Internet Explorer';
    reg = /rv:([\d\.]+)/i.exec(userAgent);
    res.version = reg ? reg[1] : '';
  } else if (/chrome/i.test(userAgent)) {
    res.name = 'Chrome';
    reg = /chrome\/([\d\.]+)/i.exec(userAgent);
    res.version = reg ? reg[1] : '';
  } else if (/safari/i.test(userAgent)) {
    res.name = 'Safari';
    reg = /version\/([\d\.]+)/i.exec(userAgent);
    res.version = reg ? reg[1] : '';
  } else if (/firefox/i.test(userAgent)) {
    res.name = 'Firefox';
    reg = /firefox\/([\d\.]+)/i.exec(userAgent);
    res.version = reg ? reg[1] : '';
  }
  return res;
}

// sm3加密
declare var SM3Digest: any;
declare var Hex: any;
export function sm3HashHexStr(value: string): string {
  //这一步是先将输入数据转成utf-8编码的字节流，然后再转成16进制可见字符
  var dataBy = Hex.utf8StrToBytes(value);
  var sm3 = new SM3Digest();
  sm3.update(dataBy, 0, dataBy.length); //数据很多的话，可以分多次update
  var sm3Hash = sm3.doFinal(); //得到的数据是个byte数组
  var sm3HashHex = Hex.encode(sm3Hash, 0, sm3Hash.length); //编码成16进制可见字符

  return sm3HashHex.toLowerCase();
}
