import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ACLService } from '@delon/acl';
import { DA_SERVICE_TOKEN, ITokenModel, ITokenService } from '@delon/auth';
import { ALAIN_I18N_TOKEN, MenuService, SettingsService, TitleService, _HttpClient } from '@delon/theme';
import { jerror } from '@shared';
import type { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzIconService } from 'ng-zorro-antd/icon';
import { Observable, zip, of, catchError, map, filter, switchMap } from 'rxjs';
import { IJsonTable } from 'src/app/shared/model/core.d';

import { ICONS } from '../../../style-icons';
import { ICONS_AUTO } from '../../../style-icons-auto';
import { ApplicationLogic } from '../../shared/logic/application.logic';
import { PrivilegeService } from './../../core/service/privilege.service';
import { I18NService } from '../i18n/i18n.service';

/**
 * Used for application startup
 * Generally used to get the basic data of the application, like: Menu Data, User Data, etc.
 */
@Injectable()
export class StartupService {
  constructor(
    iconSrv: NzIconService,
    private menuService: MenuService,
    @Inject(ALAIN_I18N_TOKEN) private i18n: I18NService,
    private settingService: SettingsService,
    private aclService: ACLService,
    private titleService: TitleService,
    @Inject(DA_SERVICE_TOKEN) private tokenSrv: ITokenService,
    private httpClient: HttpClient,
    private router: Router,
    private injector: Injector,
    private http: _HttpClient,
    private privilegeSrv: PrivilegeService
  ) {
    iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
  }
  /**
   * 是否刷新token中
   */
  private refreshToking = false;

  /**
   *
   *
   * @author 王阮强(wangruanqiang@youzhibo.cn)
   * @date 2021-02-04
   * @private
   */
  private init() {
    // 订阅token失效事件
    this.subscribeRefresh();
  }

  /**
   * 刷新token
   *
   * @author 王阮强(wangruanqiang@youzhibo.cn)
   * @date 2021-02-02
   * @protected
   * @returns {*}  {Observable<IJsonTable>}
   */
  protected refreshToken(tokenData: ITokenModel): Observable<IJsonTable> {
    // const tokenData = (this.tokenSrv.get() || {}) as ITokenModel,
    const refreshToken = tokenData['refresh_token'];
    return this.http.put<IJsonTable>(`${this.privilegeSrv.appTypeName}/v1/session/${tokenData.token}`, {
      refresh_token: refreshToken
    });
  }
  /**
   * 订阅token失效信息
   *
   * @author 王阮强(wangruanqiang@youzhibo.cn)
   * @date 2021-02-02
   * @protected
   */
  protected subscribeRefresh(): void {
    this.tokenSrv.refresh
      .pipe(
        filter(() => !this.refreshToking),
        switchMap(tokenData => {
          if (tokenData['refresh_expired'] < +new Date()) {
            // 本地刷新令牌有效期已过期，则直接返回错误信息跳转登录页面
            return of(jerror('刷新令牌无效', 12));
          }
          this.refreshToking = true;
          return this.refreshToken(tokenData);
        })
      )
      .subscribe((result: IJsonTable) => {
        this.refreshToking = false;
        // 刷新失败
        if (0 != result.state) {
          return ApplicationLogic.getInstance(this.injector).toLogin(`${result.msg}[${result.state}]`);
        }
        // 刷新成功，设置token，合并本地原有token信息
        ApplicationLogic.getInstance(this.injector).load({
          ...this.tokenSrv.get(),
          ...result.data,
          expired: +new Date() + (result.data.expire_in - 30 * 60) * 1000,
          refresh_expired: +new Date() + (result.data.expire_in - 5 * 60) * 1000
        });
        // this.tokenSrv.set(
        //   {
        //   ...this.tokenSrv.get(),
        //   ...result.data,
        //   expired: +new Date() + (result.data.expire_in - 30 * 60) * 1000,
        //   refresh_expired: +new Date() + (result.data.expire_in - 5 * 60) * 1000
        // }
        // );
      });
  }

  private viaHttp(): Observable<void> {
    const defaultLang = this.i18n.defaultLang;
    return zip(this.i18n.loadLangData(defaultLang), this.httpClient.get('assets/tmp/app-data.json')).pipe(
      catchError((res: NzSafeAny) => {
        console.warn(`StartupService.load: Network request failed`, res);
        // setTimeout(() => this.router.navigateByUrl(`/exception/500`));
        return [];
      }),
      map(([langData, appData]: [Record<string, string>, NzSafeAny]) => {
        // setting language data
        this.i18n.use(defaultLang, langData);
        this.titleService.suffix = '轩辕行';
        // Application data
        // Application information: including site name, description, year
        // this.settingService.setApp(appData.app);
        // User information: including name, avatar, email address
        // this.settingService.setUser(appData.user);
        // ACL: Set the permissions to full, https://ng-alain.com/acl/getting-started
        // this.aclService.setFull(true);
        // Menu data, https://ng-alain.com/theme/menu
        // this.menuService.add(appData.menu);
        // Can be set page suffix title, https://ng-alain.com/theme/title
        // this.titleService.suffix = appData.app.name;
      })
    );
  }

  // private viaMockI18n(): Observable<void> {
  //   const defaultLang = this.i18n.defaultLang;
  //   return this.i18n.loadLangData(defaultLang).pipe(
  //     map((langData: NzSafeAny) => {
  //       this.i18n.use(defaultLang, langData);

  //       this.viaMock();
  //     })
  //   );
  // }

  private viaMock(): Observable<void> {
    // const tokenData = this.tokenService.get();
    // if (!tokenData.token) {
    //   this.router.navigateByUrl(this.tokenService.login_url!);
    //   return;
    // }
    // mock
    // const app: any = {
    //   name: `ng-alain`,
    //   description: `Ng-zorro admin panel front-end framework`
    // };
    // const user: any = {
    //   name: 'Admin',
    //   avatar: './assets/tmp/img/avatar.jpg',
    //   email: 'cipchk@qq.com',
    //   token: '123456789'
    // };
    // // Application information: including site name, description, year
    // this.settingService.setApp(app);
    // // User information: including name, avatar, email address
    // this.settingService.setUser(user);
    // // ACL: Set the permissions to full, https://ng-alain.com/acl/getting-started
    // this.aclService.setFull(true);
    // // Menu data, https://ng-alain.com/theme/menu
    // this.menuService.add([
    //   {
    //     text: 'Main',
    //     group: true,
    //     children: [
    //       {
    //         text: 'Dashboard',
    //         link: '/dashboard',
    //         icon: { type: 'icon', value: 'appstore' }
    //       }
    //     ]
    //   }
    // ]);
    // // Can be set page suffix title, https://ng-alain.com/theme/title
    // this.titleService.suffix = app.name;

    return of();
  }

  load(): Observable<void> {
    // http
    // return this.viaHttp();
    // mock: Don’t use it in a production environment. ViaMock is just to simulate some data to make the scaffolding work normally
    // mock：请勿在生产环境中这么使用，viaMock 单纯只是为了模拟一些数据使脚手架一开始能正常运行
    // return this.viaMockI18n();
    if (window.location.hash.indexOf('/transfer/transfer?token=') == -1) {
      this.init();
    }
    return this.viaHttp();
  }
}
