import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';
import { CompanyHomeProgressComponent } from './company/routes/passport/login/progress.component';
// import { CompanyNorolegztComponent } from './company/routes/no-role/no-role.cpmponent';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'company', loadChildren: () => import('src/app/company/company.module').then(m => m.CompanyModule) }, //总行后台
      { path: 'progress', component: CompanyHomeProgressComponent, data: { title: '智慧公交' } },
      // { path: 'subbranch', loadChildren: () => import('src/app/subbranch/subbranch.module').then(m => m.SubbranchModule) },     //总行后台
      // {
      //   path: 'officialaccount',
      //   loadChildren: () => import('src/app/officialaccount/officialaccount.module').then(m => m.OfficialaccountModule)
      // }
      // { path: 'user', loadChildren: () => import('src/app/user/user.module').then(m => m.UserModule) },
      // { path: 'transfer', loadChildren: () => import('src/app/transfer/transfer.module').then(m => m.TransferModule) },
      // { path: 'wxwork', loadChildren: () => import('src/app/wxwork/wxwork.module').then(m => m.WxworkModule) },
      // // { path: 'admin', loadChildren: () => import('src/app/admin/admin.module').then((m) => m.AdminModule) },

      // { path: '**', redirectTo: 'Admin/dashboard', pathMatch: 'full' }
      { path: '**', redirectTo: 'company/passport/login', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
      // Pls refer to https://ng-alain.com/components/reuse-tab
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
