import { Component, Input } from '@angular/core';
import { MediaType } from '@delon/abc/media';

@Component({
  selector: 'hospital-components-play-videp',
  template: `
    <div class="p-md">
      <media [options]="options" [type]="type" #media [source]="mp4"></media>
    </div>
  `
})
export class ComponentsPlatVideoComponent {
  @Input() mp4: string = '';
  @Input() type: MediaType = 'video';
  options = {
    i18n: {
      speed: '速度',
      normal: '正常'
    },
    speed: { selected: 1, options: [0.5, 1, 1.5, 2] }
  }; //播放插件的国际化设置
}
