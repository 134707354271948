import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseComponent } from '../../component/app/base.component';

import { ComponentsPlatVideoComponent } from '../../component/common/play-video.component';

@Component({
  selector: 'st-widget-files',
  template: `
    <img
      nz-tooltip
      nzTooltipTitle="点击预览"
      *ngIf="type === 1"
      width="30px"
      height="30px"
      class="patient-img"
      nz-image
      [nzSrc]="path"
      alt=""
    />
    <img
      nz-tooltip
      nzTooltipTitle="点击预览"
      *ngIf="type === 2"
      class="patient-img"
      width="30px"
      height="30px"
      src="/assets/img/视频.png"
      alt=""
      (click)="playVideo(path)"
    />
    <img
      nz-tooltip
      nzTooltipTitle="点击预览"
      *ngIf="type === 3"
      class="patient-img"
      width="30px"
      height="30px"
      src="/assets/img/音频.png"
      alt=""
      (click)="playVideo(path)"
    />
  `,

  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class STCompanyFilessWidget extends BaseComponent {
  static readonly KEY = 'company-files';

  type!: number;
  path: any = '';

  playVideo(mp4: any) {
    //2是音频
    let type = 'video';
    if (this.type == 3) {
      type = 'audio';
    }
    this.modal.createStatic(ComponentsPlatVideoComponent, { mp4: mp4, type }, { size: 400 }).subscribe(res => {});
  }
  // constructor(private msg: NzMessageService) { }
}
