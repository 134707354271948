import { Injectable } from '@angular/core';
import { CacheService } from '@delon/cache';
import { _HttpClient } from '@delon/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IJsonTable, JsonTableData } from './../../shared/model/core.d';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {
  constructor(
    private http: _HttpClient,
    private cacheSvr: CacheService
  ) {}
  /**
   * 根据应用类型获取功能按钮接口地址
   *
   * @author 王阮强(wangruanqiang@youzhibo.cn)
   * @date 2021-01-05
   * @protected
   * @param {number} [appType=3]
   * @returns {*}  {string}
   */
  protected getUri(appType: number = 3): string {
    let uri: string = '';
    switch (appType) {
      case 1: // 管理员
        break;
      case 2: // 集团
        break;
      case 4: // 用户
        break;
      default:
        uri = 'hospital/v1/function/privilege_detail';
        break;
    }
    return uri;
  }

  get(menu: string, appType: number = 3): Observable<JsonTableData> {
    return new Observable<JsonTableData>(observer => {
      this.cacheSvr
        .get<IJsonTable>(this.getUri(appType), {
          mode: 'promise',
          type: 'm',
          expire: 86400
        })
        .pipe(map(value => value.data))
        .subscribe(result => {
          observer.next(result as JsonTableData);
        });
    });
  }
}
