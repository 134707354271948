import { Component, OnInit, ViewChild } from '@angular/core';
import { SFComponent, SFDateWidgetSchema, SFSchema } from '@delon/form';
import { zeroOfDayByMsTime } from '@shared';
import { Base64 } from 'js-base64';
import { BaseComponent } from 'src/app/shared/component/app/base.component';
import { IPage, IJsonTable, IGridPage } from 'src/app/shared/model/core.d';

@Component({
  selector: 'app-company-select-img.component',
  template: `
    <div class="modal-header">
      <div class="modal-title">添加图片</div>
    </div>
    <nz-spin [nzSpinning]="loading">
      <sf class="mb-lg" #condition mode="search" [schema]="condSchema" button="none" layout="inline" firstVisual="false">
        <button type="button" nz-button nzShape="circle" nzType="primary" (click)="search()">
          <i nz-icon nzType="search" nzTheme="outline"></i>
        </button>
        <button type="button" nz-button nzShape="circle" nzType="default" (click)="reset()">
          <i nz-icon nzType="redo" nzTheme="outline"></i>
        </button>
      </sf>
      <nz-image-group>
        <div *ngFor="let img of imgs" style="position: relative ;display: inline-block " class="mr-md mb-lg">
          <img nz-image width="100px" height="100px" [nzSrc]="img.file_url" />
          <div
            style="position: absolute; top: 6px; right: 6px"
            class="circle"
            [ngClass]="{ checked: img.checked }"
            (click)="img.checked = !img.checked"
          >
            <i *ngIf="img.checked" nz-icon nzType="check" nzTheme="outline"></i>
            <!-- {{ img.checked ? '✓' : '' }} -->
          </div>
          <div
            [title]="img.title + '.' + img.suffix_type"
            style="position: absolute; bottom: -22px;text-overflow: ellipsis;height:21px;white-space: nowrap;overflow: hidden;width: 100%"
            >{{ img.title }}.{{ img.suffix_type }}</div
          >
        </div>
      </nz-image-group>
      <nz-empty nzNotFoundImage="simple" *ngIf="imgs.length === 0"></nz-empty>
      <div class="text-right ">
        <nz-pagination
          (nzPageIndexChange)="onnzPageIndexChange($event)"
          (nzPageSizeChange)="onnzPageSizeChange($event)"
          [nzPageIndex]="gridPage.pi"
          [nzTotal]="gridPage.total"
          nzShowSizeChanger
          [nzPageSize]="gridPage.ps"
          [nzPageSizeOptions]="[30, 50, 100]"
        ></nz-pagination
      ></div>
    </nz-spin>
    <div class="modal-footer">
      <button nz-button [nzType]="'default'" (click)="cancel()"> 关闭 </button>
      <button nz-button [nzType]="'primary'" (click)="ok()"> 选择 </button>
    </div>
  `,
  styles: [
    `
      .circle {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border: 1px solid #c0c0c0;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 50%;
        cursor: pointer;
      }
      .circle.checked {
        color: #fff;
        background-color: #1890ff;
      }
    `
  ]
})
export class CompanyWelcomedeleteimgComponent extends BaseComponent implements OnInit {
  cancel() {
    this.modalRef.close();
  }
  override ngOnInit(): void {
    super.ngOnInit();
    this.max_count = 9 - this.item_count;
  }
  override ngAfterViewInit(): void {
    this.getdata();
  }
  patientTherapyId: any; //门诊id
  ok() {
    let ids: any = [];
    for (const iterator of this.imgs) {
      if (iterator.checked) {
        ids.push(iterator);
      }
    }
    if (ids.length == 0) {
      this.msgSrv.error('请选择有效数据');
      return;
    }
    if (ids.length > this.max_count) {
      this.msgSrv.error(`最多选择${this.max_count}张图片`);
      return;
    }
    this.modalRef.close(ids);
  }
  imgs: any = [];
  checked: boolean = false;
  status: any;
  item_count: number = 0;
  max_count: number = 0;
  //全选

  checkbox() {
    this.checked = !this.checked;
    if (this.checked) {
      for (const iterator of this.imgs) {
        iterator.checked = true;
      }
    } else {
      for (const iterator of this.imgs) {
        iterator.checked = false;
      }
    }
  }
  search() {
    this.getdata();
  }
  reset() {
    this.condition.reset();
  }
  onnzPageIndexChange($event: any) {
    this.gridPage.pi = $event;
    this.search();
  }
  onnzPageSizeChange($event: any) {
    this.gridPage.ps = $event;
    this.search();
  }
  @ViewChild('condition', { static: false }) condition!: SFComponent;
  condSchema: SFSchema = {
    properties: {
      title: {
        type: 'string',
        title: '标题',
        default: '',
        ui: {
          placeholder: '请输入',
          width: 250
        }
      },
      create_time: {
        type: 'string',
        title: '创建时间',
        ui: { widget: 'date', end: 'create_time_end', displayFormat: 'yyyy-MM-dd' } as SFDateWidgetSchema
      },
      create_time_end: {
        type: 'string',
        ui: { widget: 'date', end: 'end' } as SFDateWidgetSchema
      }
    }
  };
  gridPage: IGridPage = {
    pi: 1,
    ps: 30,
    total: 0
  };
  getdata() {
    const condition: any = {};
    Object.keys(this.condition?.value).forEach((key, idx, arr) => {
      const value = this.condition.value[key];
      if (value || value?.length > 0 || (typeof value === 'number' && value == 0)) {
        switch (key) {
          case 'create_time':
            condition['create_time_start'] = {
              field: 'create_time',
              operator: '>=',
              value: parseInt(String(zeroOfDayByMsTime(Date.parse(value))))
            };
            break;
          case 'create_time_end':
            condition['create_time_end'] = {
              field: 'create_time',
              operator: '<',
              value: parseInt(String(zeroOfDayByMsTime(Date.parse(value) + 86400000)))
            };
            break;
          case 'title':
            condition['title'] = {
              field: 'title',
              operator: 'like',
              value: `%${value}%`
            };
            break;
          default:
            condition[key] = { field: key, operator: '=', value: value };
            break;
        }
      }
    });
    condition.type = { field: 'type', operator: '=', value: 1 };
    condition.state = { field: 'state', operator: '=', value: 1 };
    this.getData(`company/v1/source_material`, this.gridPage.pi, this.gridPage.ps, condition).success.subscribe((result: IJsonTable) => {
      if (!result.data) {
        this.imgs = [];
        return;
      }
      this.imgs = result.data;
      const page = result.msg as IPage;
      this.gridPage = {
        pi: page.curr,
        ps: page.num,
        total: page.count
      };
    });
  }
}
