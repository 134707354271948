<ng-container *ngIf="items.length > 0">
  <nz-carousel [nzEffect]="strategy" #carousel nzAutoPlay [nzAutoPlaySpeed]="xFormAutoPlaySpeed * 1000">
    <div nz-carousel-content *ngFor="let item of items; let i = index" height="100%">
      <img width="100%" height="100%" [src]="item.url" />
    </div>
  </nz-carousel>
</ng-container>
<ng-container *ngIf="items.length == 0">
  <div style="height: 100%; background-color: antiquewhite" class="text-center text-md"> 图片区域 </div>
</ng-container>
