import { Component, OnInit } from '@angular/core';
import { BaseIndexComponent } from 'src/app/shared/component/app/curd/base-index.component';
import { CompanySexIndexComponent } from '../../code/sex/index.component';
import { CompanyMaterialReadComponent } from './read.component';
import { CompanyMaterialSaveComponent } from './save.component';
import { CompanyMaterialUpdateComponent } from './update.component';
import { IEventEmitter, IKVPaire } from 'src/app/shared/model/core.d';
import { STColumn, STData } from '@delon/abc/st';
import { YzbDialogComponent } from 'src/app/shared/component/common/yzb-dialog.component';
import { CompanyMaterialGroupIndexComponent } from '../material-group/index.component';
import { deepCopy } from '@delon/util';
@Component({
  selector: 'app-company-Material-index',
  templateUrl: './../../../../shared/component/app/curd/base-index.component.html'
})
export class CompanySelectMaterialIndexComponent extends BaseIndexComponent implements OnInit {
  override ngOnInit() {
    this.title = '素材管理';
    this.baseUri = 'company/v1/source_material';
    this.dictId = 1012;
    this.menuCode = 'MN0302';

    super.ngOnInit();
    this.dictComponent = {
      1011: CompanyMaterialGroupIndexComponent
    };
    this.readComponent = CompanyMaterialReadComponent;
    this.saveComponent = CompanyMaterialSaveComponent;
    this.updateComponent = CompanyMaterialUpdateComponent;
  }

  override initDialog(params: IKVPaire) {
    //视频
    this.multiSelected = true;
    this.checkBoxed = true;
    if (params['type'] == 2) {
      this.filter = Object.assign(
        this.filter,
        { zdy_type: { field: 'type', operator: '=', value: '2' } },
        { state1: { field: 'state', operator: '=', value: 1 } }
      );
    }
    //音频
    if (params['type'] == 3) {
      console.log(222333);

      this.multiSelected = false;
      this.checkBoxed = false;
      this.filter = Object.assign(
        this.filter,
        { type: { field: 'type', operator: '=', value: '3' } },
        { state1: { field: 'state', operator: '=', value: 1 } }
      );
    }
    if (!params || false !== params['isDialog']) {
      super.initDialog(params);
    }
  }

  /**
   * @description: 重写sf定义
   * @param {IEventEmitter} event
   * @return {*}
   */
  override onConditionSchema($event: IEventEmitter) {
    super.onInitForm($event);

    const { data } = $event;
    data.properties.type.ui.hidden = true;
  }
  override onInitGrid($event: IEventEmitter) {
    if (this.isDialog) {
      const { data } = $event;
      let item = [];
      for (const iterator of data) {
        if (iterator.index != 'type') {
          item.push(iterator);
        }
        if (iterator.index == 'file') {
          delete iterator.format;
          iterator.type = 'widget';
          iterator.widget = {
            type: 'company-files',
            params: ({ record }: any) => {
              return { type: record.type, path: record.file_url };
            }
          };
        }
        if (iterator.index == 'size') {
          iterator.format = (item: any, col: STColumn<any>, index: number): string => {
            return this.formatBytes(item.size);
          };
        }
      }
      data.splice(0, data.length);
      for (const iterator of item) {
        data.push(iterator);
      }
    }
  }
  formatBytes(bytes: number) {
    if (bytes === 0) return '0 B';

    const units = ['B', 'KB', 'MB', 'GB'];
    let i = Math.floor(Math.log(bytes) / Math.log(1024));

    return `${(bytes / 1024 ** i).toFixed(2)} ${units[i]}`;
  }
  override onGetData($event: IEventEmitter) {
    this.refreshParams = deepCopy($event.data);
    if (this.isDialog) {
      delete $event.data.condition.type;
    }
    this.getIndexData($event.data.curr || 1, $event.data.num || 10, $event.data.condition || {}, $event.data.sort || null);
  }
}
